import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import _ from "lodash";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Paper, Typography, Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { CHANGE_PASSWORD, CATEGORY_SELECTION, MANAGE_ACCOUNT } from "../../App";
import { LOCALE_ENGLISH, LOCALE_GERMAN } from "../../../assets/Translations/i18n";
import { deleteToken, setLanguage, getUser } from "../../../Reducers/User/UserActions";
import ACTIONS, { resetProduct } from "../../../Reducers/Product/ProductActions";
import useOuterClickNotifier from "../../../hooks/useOuterClickNotifier";
import { resetFilterPanelExpanded } from "../../../Reducers/Filter/FilterActions";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiListItemText-primary": {
      fontWeight: 600,
    },
  },
  active: {
    backgroundColor: "#F5F5F5",
  },
  userMenu: {
    zIndex: 99,
  },
  label: {
    display: "flex",
  },
  userName: {
    fontFamily: "'Ubuntu', sans-serif",
  },
  accountName: {
    fontFamily: "'Ubuntu', sans-serif",
    fontSize: 12,
  },
  userImage: {
    height: 40,
    width: 40,
  },
  userNameContainer: {
    margin: "0 0.5em 0 0.5em",
  },
}));

export const UserMenu = () => {
  const user = useSelector((state) => state.User.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [, , removeCookie] = useCookies(["authToken", "user"]);
  const language = _.get(user, "language");
  const isManager = _.get(user, "isManager");
  const organizationName = _.get(user, "organizationName");

  useEffect(() => {
    if (isManager === undefined) {
      dispatch(getUser());
    }
  }, [dispatch, isManager]);

  useEffect(() => {
    if (!language) {
      dispatch(getUser());
    }
    if (!organizationName) {
      dispatch(getUser());
    }
  }, [dispatch, language, organizationName]);

  const germanSelected = language === LOCALE_GERMAN;
  const englishSelected = language === LOCALE_ENGLISH;
  const classes = useStyles();

  const { t } = useTranslation("userMenu");

  let userName = "";
  if (user) {
    userName = `${user.givenName} ${user.familyName}`;
  }

  const [open, setOpen] = useState(false);

  const toggleCollapse = () => Promise.resolve(setOpen(!open));

  const innerRef = useRef(null);
  useOuterClickNotifier(() => {
    if (open) {
      toggleCollapse();
    }
  }, innerRef);

  const navigateToChangePwPage = () => {
    if (history.location.pathname !== `/${CHANGE_PASSWORD}`) {
      history.push(`/${CHANGE_PASSWORD}`);
      dispatch(getUser());
    }
    toggleCollapse();
  };

  const navigateToManageAccount = () => {
    if (history.location.pathname !== `/${MANAGE_ACCOUNT}`) {
      history.push(`/${MANAGE_ACCOUNT}`);
    }
    toggleCollapse();
  };

  const handleLogout = () => {
    removeCookie("user");
    removeCookie("authToken");
    dispatch(deleteToken());
    window.localStorage.setItem("logout", Date.now());
    window.localStorage.removeItem("logout");
    toggleCollapse();
  };

  const navigateToCategoriesAndResetProduct = async () => {
    await dispatch(resetProduct());
    await dispatch(resetFilterPanelExpanded());
    await dispatch(ACTIONS.resetProductChanges());
    return history.push(`/${CATEGORY_SELECTION}`);
  };

  const switchToGerman = async () => {
    await toggleCollapse();
    if (!germanSelected) {
      await dispatch(setLanguage(LOCALE_GERMAN));
      return i18n.changeLanguage(LOCALE_GERMAN).then(() => navigateToCategoriesAndResetProduct());
    }
    return null;
  };

  const switchToEnglish = async () => {
    await toggleCollapse();
    if (!englishSelected) {
      await dispatch(setLanguage(LOCALE_ENGLISH));
      return i18n.changeLanguage(LOCALE_ENGLISH).then(() => navigateToCategoriesAndResetProduct());
    }
    return null;
  };

  return (
    <div ref={innerRef}>
      <List className={classes.userMenu}>
        <ListItem button onClick={toggleCollapse}>
          <AccountCircleIcon className={classes.userImage} />
          <ListItemText
            className={classes.userNameContainer}
            primary={
              <Typography className={classes.label}>
                <span className={classes.userName}>{userName}</span>
              </Typography>
            }
            secondary={
              <Typography className={classes.label}>
                <span className={classes.accountName}>{organizationName}</span>
              </Typography>
            }
          />
          <ArrowDropDownIcon />
        </ListItem>
        <Collapse in={open} timeout="auto">
          <Paper>
            <List disablePadding>
              <ListItem button onClick={navigateToChangePwPage}>
                <ListItemText primary={t("changePassword")} />
              </ListItem>
              {isManager && (
                <ListItem button onClick={navigateToManageAccount}>
                  <ListItemText primary={t("manageAccount")} />
                </ListItem>
              )}
              <ListItem
                selected={germanSelected}
                classes={{ selected: classes.active }}
                button
                onClick={switchToGerman}
              >
                <ListItemText primary={t("german")} className={germanSelected ? classes.root : ""} />
              </ListItem>
              <ListItem
                selected={englishSelected}
                classes={{ selected: classes.active }}
                button
                onClick={switchToEnglish}
              >
                <ListItemText primary={t("english")} className={englishSelected ? classes.root : ""} />
              </ListItem>
              <ListItem button onClick={handleLogout}>
                <ListItemText primary={t("logOut")} />
              </ListItem>
            </List>
          </Paper>
        </Collapse>
      </List>
    </div>
  );
};

export default UserMenu;
