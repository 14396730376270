import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    padding: 24,
    backgroundColor: "#E0E0E0",
  },
}));

const TrialAccountNotice = () => {
  const { t } = useTranslation("trialAccountNotice");
  const classes = useStyles();

  return <h4 className={classes.root}>{t("notice")}</h4>;
};

export default TrialAccountNotice;
