export default {
  common: {
    marketOverview: "Marktübersicht",
    yes: "ja",
    changePassword: "Passwort ändern",
    back: "Zurück",
    contactUsSubject: "Anfrage Marktübersicht",
  },
  entry: {
    description:
      "Die digitale Marktübersicht mit hunderten Modellen von Wallboxen und Ladesäulen im AC- und DC- Bereich.",
    subDescription:
      "Produkte nach den wichtigsten Features & Funktionen filtern und das passende Modell für Ihren Anwendungsfall finden.",
    requestAccess: "Registrieren",
    alreadyCustomer: "Sie haben bereits einen Zugang? ",
    toLogin: "Hier einloggen",
  },
  register: {
    registerHeadline: "Registrierung",
    registerIntro:
      "Durch die Registrierung bei cosmix - Europas größte Plattform für Ladeinfrastruktur erhalten Sie Zugriff auf eine digitale Marktübersicht mit hunderten Modellen von Wallboxen und Ladesäulen im AC- und DC- Bereich.",
    firstName: "Vorname",
    lastName: "Nachname",
    company: "Unternehmen",
    pleaseEnterDiscountCode: "Rabattcode bitte in Klammer dahinter eintragen",
    organizationName: "Unternehmen",
    email: "E-Mail-Adresse",
    password: "Passwort (min. 8 Zeichen)",
    passwordRepeat: "Passwort wiederholen",
    address: "Adresse",
    street: "Straße & Hausnummer",
    addressSuffix: "E-Mail-Rechnungsadresse",
    postalCode: "Postleitzahl",
    city: "Stadt",
    country: "Land",
    alreadyRegisteredLoginNow: "Sie haben bereits einen Zugang? Hier einloggen",
    contactData: "Kontaktdaten",
    headlineServicePackage: "Service-Paket",
    subheadServicePackage: "Wählen Sie ein Paket für Ihr Unternehmen",
    headlineCategories: "Kategorien",
    subheadCategories: "Wählen Sie eine oder mehrere Kategorien",
    totalPrice: "Gesamtpreis",
    iAcceptTheTermsandConditons:
      'Ich akzeptiere die <a href="/terms" target="_blank">Allgemeinen Nutzungs- und Geschäftsbedingungen</a> der eliso GmbH',
    orderNow: "Jetzt registrieren",
    buttonDisabledHint: "Bitte füllen Sie das Formular vollständig aus",
    perMonth: "/ Monat",
    contractDurationText:
      "Alle Preise zzgl. MwSt. Vertragslaufzeit: 12 Monatsvertrag mit automatischer Verlängerung, falls nicht vor Vertragsende gekündigt wird.",
    orderSuccessfulText:
      "Danke für Ihre Registrierung. Wir prüfen nun die Freischaltung Ihres Accounts und melden uns mit den weiteren Schritten.",
  },
  login: {
    email: "E-Mail",
    password: "Passwort",
    login: "login",
    missingParameters: '"E-Mail" und "Passwort" benötigt',
    invalidCombination: "E-Mail oder Passwort nicht korrekt",
  },
  categories: {
    chooseCategory: "Bitte wählen Sie Ihre Hauptkategorie",
    categoriesEmptyMessage:
      "Keine Kategorien gefunden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.",
  },
  changePassword: {
    currentPassword: "Aktuelles Passwort",
    passwordNotCorrect: "Passwort nicht korrekt",
    newPassword: "Neues Passwort",
    requirements: "Anforderungen: Min. 8 Zeichen",
    requirementsNotMatching: "Passwort passt nicht zu den Anforderungen",
    confirmPassword: "Passwort bitte bestätigen",
    passwordNotMatching: "...stimmt noch nicht überein",
    passwordUpdateSuccessful: "Passwort erfolgreich geändert",
    ok: "OK",
  },
  overview: {
    backToCategorySelection: "Zurück zur Kategorieauswahl",
    resetFilter: "Filter Zurücksetzen",
    noFilterFound: "Für die ausgewählte Kategorie wurden leider keine Filter gefunden.",
    noProductFound: "Für Ihre Filterkombination wurden leider keine Produkte gefunden.",
    amountProducts: "Ergebnisse: {{amount}}",
  },
  userMenu: {
    loggedInAs: "Eingeloggt als:",
    logOut: "Ausloggen",
    german: "Deutsch",
    english: "Englisch",
    manageAccount: "Account verwalten",
  },
  manageAccount: {
    backToOverview: "Zurück zur Übersicht",
    user: "Benutzer",
    pricePlan: "Preisplan",
    accountData: "Firmendaten",
  },
  accountData: {
    address: "Adresse",
    street: "Straße & Hausnummer",
    addressSuffix: "E-Mail-Rechnungsadresse",
    postalCode: "Postleitzahl",
    city: "Stadt",
    country: "Land",
    save: "Speichern",
    accountSuccessfullyUpdated: "Ihre Daten wurden gespeichert.",
    errorUpdatingAccount:
      "Beim Aktualisieren Ihrer Daten ist leider ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
  },
  userList: {
    firstName: "Vorname",
    lastName: "Nachname",
    isManager: "ist Manager",
    isActive: "ist Aktiv ",
    tableHeader: "Übersicht aller Benutzer",
    search: "Suche",
    noEntries: "Keine Daten vorhanden",
    labelDisplayedRows: "{from}-{to} von {count}",
    labelRowsSelect: "Zeilen",
    labelRowsPerPage: "Zeilen pro Seite:",
    firstPage: "Erste Seite",
    previousPage: "Vorherige Seite",
    nextPage: "Nächste Seite",
    lastPage: "Letzte Seite",
    editUser: "User bearbeiten",
    deleteUser: "User löschen",
    addUser: "User hinzufügen",
    deleteUserDescription: "Möchten Sie den User {{givenName}} {{familyName}} ({{email}}) wirklich löschen?",
    cancel: "Abbrechen",
    delete: "Löschen",
    errorDeletingUser: "Der User konnte nicht gelöscht werden. Bitte versuchen Sie es später noch einmal.",
    errorUpdatingUser:
      "Der User konnte nicht aktualisiert werden. Bitte versuchen Sie es später noch einmal.",
    errorCreatingUser: "Der User konnte nicht erstellt werden. Bitte versuchen Sie es später noch einmal.",
    maxUsersReachedError:
      "Sie haben bereits die in ihrem Service-Paket maximal zulässige Anzahl an Usern erstellt.",
    updateUser: "Speichern",
    name: "Name:  ",
    email: "Email:  ",
    german: "Deutsch",
    english: "Englisch",
    language: "Sprache",
    newUserEmail: "Email",
  },
  pricing: {
    changePlan: "Plan ändern",
    headlineServicePackage: "Service-Paket",
    headlineCategories: "Kategorien",
    errorUpdatingAccount:
      "Ihr Preisplan konnte leider nicht aktualisiert werden. Bitte versuchen Sie es später noch einmal.",
    errorTooManyUsers: "Sie haben bereits mehr User angelegt, als in diesem Service-Paket enthalten sind.",
    accountSuccessfullyUpdated: "Ihr Preisplan wurde erfolgreich aktualisiert.",
    perMonth: "/ Monat",
  },
  product: {
    no: "nein",
    showAllProperties: "Alle Eigenschaften einblenden",
    showPrimaryOnly: "Nur Primäreigenschaften anzeigen",
    beforeYes: "zuvor: ja",
    beforeNo: "zuvor: nein",
    newYes: "neu: ja",
    newNo: "neu: nein",
    before: "zuvor:",
    new: "neu:",
    requestProductButton: "Produkt anfragen",
    requestService: "Anfrage Services",
    requestServiceNotice:
      "Wie können wir Sie unterstützen?\nz.B. Planung von Ladeinfrastruktur\nz.B. Installation von Ladeinfrastruktur\nz.B. Betrieb & Abrechnung von Ladeinfrastruktur",
    requestProduct: "Anfrage Produkt",
    requestProductNotice:
      "Haben Sie Fragen zum Produkt?\nz.B. Funktionen\nz.B. Anwendungsbereich\nz.B. Preis",
    requestProductSuccess: "Produktanfrage erfolgreich gesendet.",
    requestProductError: "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
    collapseProducts: "Produkte von {{manufacturer}} einklappen",
    extendProducts: "{{count}} Produkte von {{manufacturer}} anzeigen",
    displayProductInformation: "Produktdetails",
  },
  productChangesMenu: {
    changes: "Produkt-Updates",
    changesSince: "Produkt-Updates seit",
    newProducts: "neue Produkte",
    changedProperties: "geänderte Eigenschaften",
    productsWithChangedProperties: "Produkte mit geänderten Eigenschaften",
    setToLastLogin: "Auf letzten Login setzen ({{lastLogin}})",
    invalidDateMessage: "Ungültiges Datum",
    minDateMessage: "Das Datum liegt vor dem min. Datum",
    maxDateMessage: "Das Datum liegt nach dem max. Datum",
    amountNewFilters: "{{count}} neue Filter",
    amountNewProducts: "{{count}} neue Produkte",
    amountChangedProperties: "{{count}} geänderte Eigenschaften",
    amountChangedProducts: "{{count}} Produkte mit geänderten Eigenschaften",
    okLabel: "OK",
    cancelLabel: "Abbrechen",
  },
  filterCheckBox: {
    yes: "ja",
    no: "nein",
  },
  searchForm: {
    label: "Suche",
    tooltip: "Suche mit Eingabetaste bestätigen",
  },
  footer: {
    dataProtection: "Datenschutzerklärung",
    termsAndConditions: "AGB",
    contactUs: "Kontaktieren Sie uns",
    imprint: "Impressum",
  },
  filter: {
    allItemsSameValue: "Alle Produkte haben den Wert: {{value}}",
  },
  modal: {
    buttonName: "Features und Funktionsweise",
    close: "Schließen",
    heading1: "Die digitale Marktübersicht für Wallboxen und Ladesäulen",
    description1:
      "Verschaffen Sie sich einen schnellen Überblick, welche Ladesysteme am Markt verfügbar sind und zu Ihrem Projektvorhaben passen.",
    heading2: "Was erwartet mich?",
    description2:
      "Mit Ihrem Zugang haben Sie Zugriff auf unser übersichtliches Tool, das es Ihnen ermöglicht, die am Markt verfügbaren Ladestationen im AC- & DC- Bereich mit all ihren wichtigen Eigenschaften einzusehen. Die Produkte können durchsucht und gefiltert werden. So erhalten Sie schnell und übersichtlich die Ladestation, die zu Ihrem Projekt passt.",
    heading3: "Wie funktioniert die Software?",
    description3:
      "Ganz einfach: Sie öffnen die Webseite in Ihrem Browser (keine Vorinstallation notwendig), loggen sich mit Ihren Zugangsdaten ein und wählen eine Hauptkategorie. Danach werden Sie automatisch auf unsere digitale Marktübersicht, mit den am Markt verfügbaren Produkten in der von Ihnen gewählten Kategorie, geleitet. Sie können sich aktuell zwischen AC- & DC- Wallboxen und/ oder Ladesäulen entscheiden. Nach Ihren individuellen Suchkriterien und über 100 Filtern können Sie die Suche in den einzelnen Kategorien Ihren Bedürfnissen anpassen. Spielt das Thema Eichrechtkonformität und die Zertifizierung eines bestimmten Backends eine Rolle in Ihrem Projekt, oder suchen Sie vielleicht nach einer speziellen Ladestation mit einer Ladeleistung von 150 kW, 2 CCS Anschlüssen mit simultaner Lademöglichkeit? Sie können das individuell, einfach und bequem steuern. In einer übersichtlichen Darstellung werden Ihnen im nächsten Schritt die entsprechenden Produkte angezeigt.",
    heading4: "Wie erhalte ich einen Zugang?",
    description4:
      'Drücken Sie einfach den "Zugang anfragen" Button und schreiben uns. Wir melden uns umgehend bei Ihnen zum weiteren Vorgehen und beraten Sie gerne zur Anwendung der digitalen Marktübersicht und der passenden Auswahl der Kategorie für Sie.',
    searchResultScreenshot: "Ansicht der Suchergebnisse:",
  },
  trialAccountNotice: {
    notice: "In der Testversion haben nur die ersten drei Filter Auswirkungen auf das Ergebnis.",
  },
};
