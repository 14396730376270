import axios from "axios";
import qs from "qs";
import _ from "lodash";

const { CancelToken } = axios;
let source = CancelToken.source();

export const baseUrl = `${process.env.REACT_APP_HOST}`;
const ax = axios.create({
  baseURL: baseUrl,
  timeout: `${process.env.REACT_APP_TIMEOUT}`,
  cancelToken: source.token,
  headers: { Accept: "application/json" },

  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: "brackets" });
  },
});

export class Api {
  static _token = "";

  static request(config, successCb, failCb) {
    const promise = ax.request(config);
    if (_.isFunction(successCb)) {
      promise.then(successCb);
    }
    if (_.isFunction(failCb)) {
      promise.catch(failCb);
    }
    return promise;
  }

  static cancel() {
    source.cancel();
    source = CancelToken.source();
    ax.defaults.cancelToken = source.token;
  }

  static setToken(token) {
    this._token = token;
    ax.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  static getToken() {
    return this._token;
  }

  static init(config) {
    config.map((key) => {
      ax.defaults[key] = config[key];
      return null;
    });
  }
}

export const isCancelError = (error) => error instanceof axios.Cancel;

export default Api;
