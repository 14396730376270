import React, { useEffect, useState } from "react";
import { Tabs, Tab, Button, makeStyles } from "@material-ui/core";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import BusinessIcon from "@material-ui/icons/Business";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { UserList } from "../../Components/UserList/UserList";
import { Pricing } from "../../Components/Pricing/Pricing";
import { CATEGORY_SELECTION } from "../../App";
import ACTIONS from "../../../Reducers/Product/ProductActions";
import { useDispatch, useSelector } from "react-redux";
import AccountData from "../../Components/AccountData/AccountData";
import { getAccount } from "../../../Reducers/Manager/ManagerActions";

const useStyles = makeStyles({
  backButtonContainer: {
    display: "flex",
  },
  backButton: {
    marginLeft: 30,
  },
  tabHeader: {
    margin: "30px 30px 15px 30px",
    backgroundColor: "#F5F5F5",
  },
});

export const ManageAccountPage = () => {
  const { t } = useTranslation("manageAccount");
  const history = useHistory();
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState("userData");
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.Manager);

  useEffect(() => {
    if (!account) {
      dispatch(getAccount());
    }
  }, [account]);

  const navigateToCategoryOverview = () => {
    dispatch(ACTIONS.resetProductChanges());
    history.push(`/${CATEGORY_SELECTION}`);
  };

  const tabChange = (event, value) => {
    setCurrentTab(value);
  };

  const TabElement = ({ value }) => {
    switch (value) {
      case "userData":
        return <UserList />;
      case "pricePlan":
        return <Pricing />;
      case "accountData":
        return <AccountData />;
      default:
        return null;
    }
  };
  TabElement.propTypes = {
    index: PropTypes.number,
  };
  TabElement.defaultProps = {
    index: null,
  };

  const isManufacturerAccount = account && account.servicePackage.type === "manufacturer";

  return (
    <div>
      <div className={classes.backButtonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={navigateToCategoryOverview}
          className={classes.backButton}
        >
          {t("backToOverview")}
        </Button>
      </div>
      <Tabs
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={tabChange}
        className={classes.tabHeader}
      >
        <Tab value="userData" icon={<PersonPinIcon />} label={t("user")} />
        {!isManufacturerAccount && <Tab value="pricePlan" icon={<LocalOfferIcon />} label={t("pricePlan")} />}
        <Tab value="accountData" icon={<BusinessIcon />} label={t("accountData")} />
      </Tabs>
      <TabElement value={currentTab} />
    </div>
  );
};

export default ManageAccountPage;
