import React from "react";
import removeLeadingAsterisks from "../../../../utils/helpers";
import { Divider, ListItem, Typography } from "@material-ui/core";
import { NewBadgeSmall } from "../../NewBadge/NewBadge";
import { LOCALE_ENGLISH, LOCALE_GERMAN } from "../../../../assets/Translations/i18n";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  propertyNewBadge: {
    marginTop: 2,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  propertyNewBadgeHighlighted: {
    marginTop: 2,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    backgroundColor: "#00637c",
  },
  valueContainer: {
    display: "flex",
    flexDirection: "column",
  },
  newValue: {
    color: "#4153AF",
    fontWeight: 500,
    fontSize: "0.9rem",
    textAlign: "right",
  },
  oldValue: {
    fontSize: "0.8rem",
    opacity: 0.6,
    textAlign: "right",
  },
}));

const PropertyGroup = ({ propertyGroup, userLanguage, handleSelectItem, selectedRow }) => {
  const classes = useStyles();
  const { t } = useTranslation("product");

  const groupName = propertyGroup.groupName ? removeLeadingAsterisks(propertyGroup.groupName) : false;
  const propertyGroupProperties = propertyGroup.properties || [];

  return (
    <React.Fragment key={propertyGroup.identifier}>
      {groupName && (
        <ListItem className="Product-row title-container">
          <Typography className="Product-row title">{groupName}</Typography>
        </ListItem>
      )}
      {propertyGroupProperties.map((property) => {
        const propertyName = property.name ? removeLeadingAsterisks(property.name) : false;
        return (
          <React.Fragment key={propertyName + property.value}>
            {property.isNew && (
              <div
                className={
                  selectedRow === property.identifier
                    ? classes.propertyNewBadgeHighlighted
                    : classes.propertyNewBadge
                }
              >
                <NewBadgeSmall language={userLanguage} />
              </div>
            )}
            <ListItem
              onClick={() => handleSelectItem(property)}
              className={selectedRow === property.identifier ? "Product-row highlight" : "Product-row"}
            >
              {propertyName && (
                <Typography className="Product-row name-container">
                  {userLanguage === LOCALE_GERMAN && <Hyphenated language={de}>{propertyName}</Hyphenated>}
                  {userLanguage === LOCALE_ENGLISH && <Hyphenated>{propertyName}</Hyphenated>}
                </Typography>
              )}
              {!property.oldValue && (
                <Typography
                  className={
                    propertyName ? "Product-row value-container" : "Product-row value-container-full-width"
                  }
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {property.value === "true"
                    ? t("yes")
                    : property.value === "false"
                    ? t("no")
                    : property.value}
                </Typography>
              )}
              {property.oldValue && (
                <Typography
                  className={
                    propertyName ? "Product-row value-container" : "Product-row value-container-full-width"
                  }
                >
                  <div className={classes.valueContainer}>
                    <Typography className={classes.newValue}>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {property.value === "true"
                        ? t("newYes")
                        : property.value === "false"
                        ? t("newNo")
                        : `${t("new")} ${property.value}`}
                    </Typography>
                    <Typography className={classes.oldValue}>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {property.oldValue === "true"
                        ? t("beforeYes")
                        : property.oldValue === "false"
                        ? t("beforeNo")
                        : `${t("before")} ${property.oldValue}`}
                    </Typography>
                  </div>
                </Typography>
              )}
            </ListItem>
            <Divider />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default PropertyGroup;
