import { useEffect } from "react";

const useOuterClickNotifier = (onOuterClick, innerRef) => {
  useEffect(() => {
    if (innerRef.current) {
      // eslint-disable-next-line no-use-before-define
      document.addEventListener("click", handleClick);
    }

    // eslint-disable-next-line no-use-before-define
    return () => document.removeEventListener("click", handleClick);

    function handleClick(e) {
      // eslint-disable-next-line no-unused-expressions
      innerRef.current && !innerRef.current.contains(e.target) && onOuterClick(e);
    }
  }, [onOuterClick, innerRef]);
};

export default useOuterClickNotifier;
