import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { UserMenu } from "../UserMenu/UserMenu";
import { ProductChangesMenu } from "../ProductChangesMenu/ProductChangesMenu";
import logo from "../../../assets/logo.png";
import { useHistory } from "react-router";
import { OVERVIEW } from "../../App";
import {useState} from "react";
import {useLocation} from "react-router";

const useStyles = makeStyles({
  header: {
    display: "flex",
    height: 140,
  },
  logo: {
    alignSelf: "center",
    height: 100,
    padding: 10,
    paddingRight: 20,
    boxSizing: "content-box",
  },
  headerText: {
    alignSelf: "center",
    fontSize: 40,
    fontFamily: "Ubuntu, sans-serif",
  },
  menuContainer: {
    position: "absolute",
    right: "0.5rem"
  }
});

const Header = () => {

  const user = useSelector((state) => state.User.user);
  const history = useHistory();
  const [showChangesMenu, setShowChangesMenu] = useState(history.location.pathname === `/${OVERVIEW}`);

  function useLocationPathname() {
    let location = useLocation();
    React.useEffect(() => {
      setShowChangesMenu(location.pathname === `/${OVERVIEW}`);
    }, [location]);
  }

  useLocationPathname();

  const classes = useStyles();

  return user ? (
    <header className={classes.header}>
      <img className={classes.logo} src={logo} alt="logo" />
      <div className={classes.menuContainer}>
        <UserMenu />
        {showChangesMenu && <ProductChangesMenu />}        
      </div>      
    </header>
  ) : null;
};

export default Header;
