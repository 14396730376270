import React from "react";
import { Typography, Snackbar, Slide, Button, CircularProgress } from "@material-ui/core";
import "./Notifications.css";

const transition = (props) => <Slide {...props} direction="right" />;
const MAX_TIMEOUT = 20000;

/* ACTIONS */
const NOTIFICATIONS_ADD = "NOTIFICATIONS_ADD";
const NOTIFICATIONS_DELETE = "NOTIFICATIONS_DELETE";

export const deleteMessageById = (id) => {
  const detail = {
    id,
  };
  const messageEvt = new CustomEvent(NOTIFICATIONS_DELETE, { detail });
  return document.dispatchEvent(messageEvt);
};

export const showMessage = (body, timeout = 2300) => {
  const id = Date.now().toString() + Math.random();
  const timeoutId = setTimeout(() => {
    deleteMessageById(id);
  }, timeout || MAX_TIMEOUT);

  const messageItem = {
    id,
    timeout,
    timeoutId,
    body,
  };

  const detail = {
    messageItem,
  };
  const messageEvt = new CustomEvent(NOTIFICATIONS_ADD, { detail });
  document.dispatchEvent(messageEvt);
  return messageItem;
};

export const showLoadingMessage = () => {
  const body = (
    <span className="Notifications-body --Loader">
      <CircularProgress />
    </span>
  );
  return showMessage(body, 10);
};

/* COMPONENT */

export const Notifications = () => {
  const [messages, setMessages] = React.useState([]);

  const handleDelete = (event) => {
    const { detail } = event;
    const { id } = detail;
    setMessages((_messages) => _messages.filter((m) => m.id !== id));
  };

  const handleNewMessage = (event) => {
    const { detail } = event;
    const { messageItem } = detail;

    messages.push(messageItem);
    setMessages([...messages]);
  };

  React.useEffect(() => {
    document.addEventListener(NOTIFICATIONS_ADD, handleNewMessage, false);
    document.addEventListener(NOTIFICATIONS_DELETE, handleDelete, false);
    // clean up
    return () => {
      document.removeEventListener(NOTIFICATIONS_ADD, handleNewMessage);
      document.removeEventListener(NOTIFICATIONS_DELETE, handleDelete);
    };
  });

  const isPrimitive = (input) => {
    const inputType = typeof input;
    const primitives = ["string", "number", "boolean"];
    return primitives.indexOf(inputType) !== -1;
  };

  return (
    <div className="Notifications">
      {messages.map((m) => (
        <div key={m.id} className="Notifications-item" style={{ animationDuration: `${m.timeout}ms` }}>
          <Snackbar
            key={`${m.id}snackbar`}
            className="Notifications-Snackbar"
            open
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            onClose={() => {}}
            TransitionComponent={transition}
            ContentProps={{
              "aria-describedby": m.id,
            }}
            message={
              <div className="Notifications-message" key={`${m.id}message`}>
                {isPrimitive(m.body) && <Typography component="span">{m.body}</Typography>}
                {!isPrimitive(m.body) && m.body}
              </div>
            }
            action={[
              <Button
                key={`${m.id}button`}
                className="Notifications-close"
                onClick={() => deleteMessageById(m.id)}
                color="inherit"
              >
                x
              </Button>,
            ]}
          />
        </div>
      ))}
    </div>
  );
};

export default Notifications;
