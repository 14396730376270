import React from "react";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import SecurityIcon from "@material-ui/icons/Security";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Mail from "@material-ui/icons/Mail";
import NotesIcon from "@material-ui/icons/Notes";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DATA_PROTECTION, IMPRINT, TERMS } from "../../App";
import { ELISO_MAIL_ADDRESS } from "../../../Core/Constants";

const useStyles = makeStyles({
  bottomNavigation: {
    height: 70,
    backgroundColor: "#00637c",
    boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)",
    position: "fixed",
    bottom: 0,
    width: "100%",
    marginTop: 24,
  },
  navigationAction: {
    color: "white",
    fontSize: "2rem",
  },
});

const Footer = () => {
  const classes = useStyles();
  const history = useHistory();

  const { t } = useTranslation("footer");

  const navigateToDataProtectionPage = () => {
    if (history.location.pathname !== `/${DATA_PROTECTION}`) {
      history.push(`/${DATA_PROTECTION}`);
    }
  };

  const navigateToTerms = () => {
    if (history.location.pathname !== `/${TERMS}`) {
      window.open(`/${TERMS}`, "_blank");
    }
  };

  const openMailClient = () => {
    window.location = `mailto:${ELISO_MAIL_ADDRESS}?subject=${t("contactUsSubject")}`;
  };

  const navigateToImprintPage = () => {
    if (history.location.pathname !== `/${IMPRINT}`) {
      history.push(`/${IMPRINT}`);
    }
  };

  return (
    <BottomNavigation
      showLabels
      className={classes.bottomNavigation}
      style={get(history, "location.pathname", "") === "/" ? { position: "relative" } : null}
    >
      <BottomNavigationAction
        className={classes.navigationAction}
        label={t("termsAndConditions")}
        icon={<AssignmentIcon />}
        onClick={navigateToTerms}
      />
      <BottomNavigationAction
        className={classes.navigationAction}
        label={t("contactUs")}
        icon={<Mail />}
        onClick={openMailClient}
      />
      <BottomNavigationAction
        className={classes.navigationAction}
        label={t("dataProtection")}
        icon={<SecurityIcon />}
        onClick={navigateToDataProtectionPage}
      />
      <BottomNavigationAction
        className={classes.navigationAction}
        label={t("imprint")}
        icon={<NotesIcon />}
        onClick={navigateToImprintPage}
      />
    </BottomNavigation>
  );
};

export default Footer;
