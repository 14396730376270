import { useState } from "react";

export default function useFilterState(filterState) {
  const [state, setState] = useState(filterState);

  function smartSetState(newState) {
    if (state.filterState !== newState.filterState) {
      setState(newState);
    }
  }

  return [state, smartSetState];
}
