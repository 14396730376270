import { fetchData, deleteData, putData, postData } from "../Api/ApiActions";

export const RECEIVE_ACCOUNT_USERS = "MANAGER_RECEIVE_ACCOUNT_USERS";
export const RECEIVE_DELETE_USER = "MANAGER_RECEIVE_DELETE_USER";
export const RECEIVE_DELETE_USER_ERROR = "MANAGER_RECEIVE_DELETE_USER_ERROR";
export const RESET_DELETE_USER_ERROR = "MANAGER_RESET_DELETE_USER_ERROR";
export const RECEIVE_CREATE_USER = "MANAGER_RECEIVE_CREATE_USER";
export const RECEIVE_CREATE_USER_ERROR = "MANAGER_RECEIVE_CREATE_USER_ERROR";
export const RESET_CREATE_USER_ERROR = "MANAGER_RESET_CREATE_USER_ERROR";
export const RECEIVE_UPDATE_USER = "MANAGER_RECEIVE_UPDATE_USER";
export const RECEIVE_UPDATE_USER_ERROR = "MANAGER_RECEIVE_UPDATE_USER_ERROR";
export const RESET_UPDATE_USER_ERROR = "MANAGER_RESET_UPDATE_USER_ERROR";
export const RECEIVE_UPDATE_ACCOUNT = "MANAGER_RECEIVE_UPDATE_ACCOUNT";
export const RECEIVE_UPDATE_ACCOUNT_ERROR = "MANAGER_RECEIVE_UPDATE_ACCOUNT_ERROR";
export const RECEIVE_ACCOUNT = "MANAGER_RECEIVE_ACCOUNT";
export const RESET_UPDATE_ACCOUNT = "MANAGER_RESET_UPDATE_ACCOUNT";

export const ROUTE_MANAGER_USERS = "manager-api/accounts/users";
export const ROUTE_MANAGER_ACCOUNTS = "manager-api/accounts";

export const receiveAccountUsers = (data) => ({
  type: RECEIVE_ACCOUNT_USERS,
  users: data,
});

export const fetchAccountUsers = () => (dispatch) => {
  dispatch(fetchData(ROUTE_MANAGER_USERS, receiveAccountUsers));
};

export const receiveDeleteUser = (data) => ({
  type: RECEIVE_DELETE_USER,
  data,
});

export const receiveDeleteUserError = (data) => ({
  type: RECEIVE_DELETE_USER_ERROR,
  data,
});

export const resetDeleteUserError = () => ({
  type: RESET_DELETE_USER_ERROR,
});

export const deleteUser = (userId) => (dispatch) => {
  return dispatch(
    deleteData(`${ROUTE_MANAGER_USERS}/${userId}`, {}, receiveDeleteUser, receiveDeleteUserError)
  );
};

export const receiveUpdateUser = (data) => ({
  type: RECEIVE_UPDATE_USER,
  data,
});

export const receiveUpdateUserError = (data) => ({
  type: RECEIVE_UPDATE_USER_ERROR,
  data,
});

export const resetUpdateUserError = () => ({
  type: RESET_UPDATE_USER_ERROR,
});

export const updateUser = (user) => (dispatch) => {
  return dispatch(
    putData(`${ROUTE_MANAGER_USERS}/${user.id}`, user, receiveUpdateUser, receiveUpdateUserError)
  );
};

export const receiveCreateUser = (data) => ({
  type: RECEIVE_CREATE_USER,
  data,
});

export const receiveCreateUserError = (source, error, response) => ({
  type: RECEIVE_CREATE_USER_ERROR,
  source,
  error,
  response,
});

export const resetCreateUserError = () => ({
  type: RESET_CREATE_USER_ERROR,
});

export const createUser = (user) => (dispatch) => {
  return dispatch(postData(`${ROUTE_MANAGER_USERS}`, user, receiveCreateUser, receiveCreateUserError));
};

export const receiveAccount = (account) => ({
  type: RECEIVE_ACCOUNT,
  account,
});

export const getAccount = () => (dispatch) => {
  return dispatch(fetchData(ROUTE_MANAGER_ACCOUNTS, receiveAccount));
};

export const receiveUpdateAccount = (account) => ({
  type: RECEIVE_UPDATE_ACCOUNT,
  account,
});

export const receiveUpdateAccountError = (source, error, response) => ({
  type: RECEIVE_UPDATE_ACCOUNT_ERROR,
  source,
  error,
  response,
});

export const resetUpdateAccount = () => ({
  type: RESET_UPDATE_ACCOUNT,
});

export const updateAccount = (servicePackageId, categories, organizationName) => (dispatch, getState) => {
  const { account } = getState().Manager;
  const newAccount = { ...account, servicePackageId, categories, organizationName };
  dispatch(putData(ROUTE_MANAGER_ACCOUNTS, newAccount, receiveUpdateAccount, receiveUpdateAccountError));
};

export const updateAccountAddress = (billingAddress) => (dispatch, getState) => {
  const { account } = getState().Manager;
  const newAccount = {
    organizationName: account.organizationName,
    servicePackageId: account.servicePackage.id,
    categories: account.categories.map((c) => c.id),
    billingAddress,
  };
  dispatch(putData(ROUTE_MANAGER_ACCOUNTS, newAccount, receiveUpdateAccount, receiveUpdateAccountError));
};
