import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Typography, ButtonBase, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchCategories,
  setActiveCategory,
} from "../../../Reducers/Product/ProductActions";
import { OVERVIEW } from "../../App";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { resetFilterPanelExpanded } from "../../../Reducers/Filter/FilterActions";

const useStyles = makeStyles({
  card: {
    width: 300,
    height: 300,
    marginTop: 50,
    backgroundColor: "white",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    transition: "all .25s linear",
    boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.4)",
    "&:hover": {
      boxShadow: "-1px 10px 29px 0px rgba(0,0,0,0.8)",
    },
  },
  cardAction: {
    height: "100%",
    width: "100%",
  },
  cardActiveCategory: {
    width: 300,
    height: 300,
    marginTop: 50,
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    transition: "all .25s linear",
    boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.4)",
    backgroundColor: "#00637c",
    "&:hover": {
      boxShadow: "-1px 10px 29px 0px rgba(0,0,0,0.8)",
    },
  },
  cardContainer: {
    alignSelf: "center",
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    maxWidth: 700,
    width: 700,
  },
  cardTitle: {
    fontSize: 28,
    color: "#00637c",
  },
  cardTitleActive: {
    fontSize: 28,
    color: "#fafafa",
  },
  categorySelectionPage: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "0 20px",
    fontFamily: "'Ubuntu', sans-serif",
  },
  heading: {
    fontSize: 28,
    fontWeight: 400,
    lineHeight: 1.04,
    letterSpacing: 0,
  },
  cardSmall: {
    width: 150,
    height: 150,
    marginTop: 25,
    backgroundColor: "white",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    transition: "all .25s linear",
    boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.4)",
    "&:hover": {
      boxShadow: "-1px 10px 29px 0px rgba(0,0,0,0.8)",
    },
  },
  cardActiveCategorySmall: {
    width: 150,
    height: 150,
    marginTop: 25,
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    transition: "all .25s linear",
    boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.4)",
    backgroundColor: "#00637c",
    "&:hover": {
      boxShadow: "-1px 10px 29px 0px rgba(0,0,0,0.8)",
    },
  },
  cardContainerSmall: {
    alignSelf: "center",
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    maxWidth: 350,
    width: 350,
  },
  cardTitleSmall: {
    fontFamily: "'Ubuntu', sans-serif",
    fontSize: 20,
    color: "#00637c",
  },
  cardTitleActiveSmall: {
    fontFamily: "'Ubuntu', sans-serif",
    fontSize: 20,
    color: "#fafafa",
  },
  headingSmall: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 1.04,
    letterSpacing: 0,
  },
});

export const CategorySelectionPage = () => {
  const classes = useStyles();
  const categoriesLoaded = useSelector((state) => state.Product.categoriesLoaded);
  const categories = useSelector((state) => state.Product.categories);
  const activeCategory = useSelector((state) => state.Product.activeCategory);
  const fetchingCategories = useSelector((state) => state.Product.fetchingCategories);
  const singleCategoryUser = categories.length === 1;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("categories");
  const { height, width } = useWindowDimensions();

  const isSmallScreen = height < 1050 || width < 1680;

  const setCategory = async (category) => {
    await dispatch(setActiveCategory(category));
    await dispatch(resetFilterPanelExpanded());
    history.push(`/${OVERVIEW}`);
  };

  if (!categoriesLoaded && !fetchingCategories) {
    dispatch(fetchCategories());
  }

  if (categoriesLoaded && singleCategoryUser) {
    dispatch(setActiveCategory(categories[0]));
    history.push(`/${OVERVIEW}`);
  }

  const showCategoriesLoader = !categories;
  const showCategoriesEmptyMessage = categories.length === 0 && categoriesLoaded;
  return (
    <div className={classes.categorySelectionPage}>
      {!showCategoriesLoader && !showCategoriesEmptyMessage && (
        <h1 className={isSmallScreen ? classes.headingSmall : classes.heading}>{t("chooseCategory")}</h1>
      )}
      {showCategoriesEmptyMessage && <Typography>{t("categoriesEmptyMessage")}</Typography>}
      {showCategoriesLoader && <CircularProgress style={{ alignSelf: "center", marginTop: 50 }} />}
      {!showCategoriesLoader && (
        <div className={isSmallScreen ? classes.cardContainerSmall : classes.cardContainer}>
          {categories &&
            !fetchingCategories &&
            categories.map((category) => (
              <Card
                /* eslint-disable-next-line no-nested-ternary */
                className={
                  category === activeCategory
                    ? isSmallScreen
                      ? classes.cardActiveCategorySmall
                      : classes.cardActiveCategory
                    : isSmallScreen
                    ? classes.cardSmall
                    : classes.card
                }
                key={category.name}
              >
                <ButtonBase className={classes.cardAction} onClick={() => setCategory(category)}>
                  <CardContent>
                    <Typography
                      /* eslint-disable-next-line no-nested-ternary */
                      className={
                        category === activeCategory
                          ? isSmallScreen
                            ? classes.cardTitleActiveSmall
                            : classes.cardTitleActive
                          : isSmallScreen
                          ? classes.cardTitleSmall
                          : classes.cardTitle
                      }
                    >
                      {category.mainCategory}
                      <br />
                      {category.subCategory}
                    </Typography>
                  </CardContent>
                </ButtonBase>
              </Card>
            ))}
        </div>
      )}
    </div>
  );
};

export default CategorySelectionPage;
