import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import i18n from "../../../assets/Translations/i18n";
import { ScrollToTopButton } from "../../Components/ScrollToTopButton/ScrollToTopButton";
import TermsDE from "./Terms_de.md";
import TermsEN from "./Terms_en.md";

const useStyles = makeStyles({
  termsPage: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    maxWidth: 1110,
    margin: "0 auto",
    padding: "0 20px",
    whiteSpace: "pre-line",
    maxHeight: "calc(100vh - 290px)",
  },
  button: {
    color: "#00637c",
    fontSize: "1rem",
    textTransform: "none",
  },
  hr: {
    width: "100%",
    border: "1px solid #00637c",
    borderRadius: 1,
  },
  text: {
    paddingBottom: 120,
    textAlign: "left",
  },
});

export const TermsPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const [markdown, setMarkdown] = useState({ termsMarkdown: "" });

  const { t } = useTranslation();

  const fetchMarkdown = async (signal) => {
    let Terms;
    if (i18n.language === "de_DE") {
      Terms = TermsDE;
    } else if (i18n.language === "en_US") {
      Terms = TermsEN;
    }
    try {
      const res = await fetch(Terms, { signal });
      const termsMarkdown = await res.text();
      setMarkdown({ termsMarkdown });
    } catch (error) {
      console.error("Error during fetching of markdown:", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    fetchMarkdown(signal);
    return () => abortController.abort();
  }, []);

  return (
    <div className={classes.termsPage}>
      <Typography className={classes.text} component="div">
        <ReactMarkdown source={markdown.termsMarkdown} />
      </Typography>
      <ScrollToTopButton />
    </div>
  );
};

export default TermsPage;
