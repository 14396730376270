import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { useCookies } from "react-cookie";

import { deleteToken } from "../../../Reducers/User/UserActions";

import { LOGIN } from "../../App";
import { LOCALE_ENGLISH, LOCALE_GERMAN } from "../../../assets/Translations/i18n";

const propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};
const defaultProps = {
  component: () => {},
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.User.user);
  const hasAuthError = useSelector((state) => state.Api.hasAuthError);
  const dispatch = useDispatch();
  const [cookies, , removeCookie] = useCookies(["authToken", "user", "i18next"]);

  if (hasAuthError) {
    dispatch(deleteToken());
    removeCookie("authToken");
    removeCookie("user");
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={
              cookies.i18next === LOCALE_GERMAN
                ? { pathname: `/${LOGIN}` }
                : { pathname: `/${LOGIN}`, search: `?lng=${LOCALE_ENGLISH}` }
            }
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = propTypes;
PrivateRoute.defaultProps = defaultProps;

export default PrivateRoute;
