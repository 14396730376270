export const SET_EXPANDED_FILTER_PANEL = "FILTER_SET_EXPANDED_FILTER_PANEL";
export const RESET_EXPANDED_FILTER_PANELS = "FILTER_RESET_EXPANDED_FILTER_PANELS";
export const SET_EXPANDED_FILTER_DETAIL_PANEL = "FILTER_SET_EXPANDED_FILTER_DETAIL_PANEL";

export const setFilterPanelExpanded = (groupName) => (dispatch) =>
  dispatch({
    type: SET_EXPANDED_FILTER_PANEL,
    groupName,
  });
export const setFilterDetailPanelExpanded = (filterName) => (dispatch) =>
  dispatch({
    type: SET_EXPANDED_FILTER_DETAIL_PANEL,
    filterName,
  });
export const resetFilterPanelExpanded = () => (dispatch) =>
  dispatch({
    type: RESET_EXPANDED_FILTER_PANELS,
  });
