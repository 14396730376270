import { fetchData, postData } from "../Api/ApiActions";
import i18n, { LOCALE_GERMAN } from "../../assets/Translations/i18n";

export const RECEIVE_CATEGORIES = "REGISTRATION_RECEIVE_CATEGORIES";
export const LOAD_CATEGORIES = "REGISTRATION_LOAD_CATEGORIES";
export const RECEIVE_REGISTRATION = "REGISTRATION_RECEIVE_REGISTRATION";
export const RESET_REGISTRATION_STATUS = "REGISTRATION_RESET_REGISTRATION_STATUS";
export const RECEIVE_REGISTRATION_ERROR = "REGISTRATION_RECEIVE_REGISTRATION_ERROR";
export const LOAD_SERVICE_PACKAGES = "REGISTRATION_LOAD_SERVICE_PACKAGES";
export const RECEIVE_SERVICE_PACKAGES = "REGISTRATION_RECEIVE_SERVICE_PACKAGES";
export const RECEIVE_SERVICE_PACKAGES_FOR_UPGRADE = "REGISTRATION_RECEIVE_SERVICE_PACKAGES_FOR_UPGRADE";
export const ROUTE_SERVICE_PACKAGES_GERMAN = "registration/de/service-packages";
export const ROUTE_SERVICE_PACKAGES_ENGLISH = "registration/en/service-packages";
export const ROUTE_SERVICE_PACKAGES_FOR_UPGRADE = "manager-api/service-packages";
export const ROUTE_REGISTER = "registration";
export const ROUTE_CATEGORIES_GERMAN = "registration/de/categories";
export const ROUTE_CATEGORIES_ENGLISH = "registration/en/categories";

export const receiveRegistration = (data) => ({
  type: RECEIVE_REGISTRATION,
  data,
});

export const receiveRegistrationError = (source, error, response) => ({
  type: RECEIVE_REGISTRATION_ERROR,
  response,
  status: response && response.status,
});

const resetRegistrationStatus = () => ({
  type: RESET_REGISTRATION_STATUS,
});

const loadCategories = () => ({
  type: LOAD_CATEGORIES,
});

const loadServicePackages = () => ({
  type: LOAD_SERVICE_PACKAGES,
});

const exportFunctions = {
  resetRegistrationStatus,
  loadCategories,
  loadServicePackages,
};

export default exportFunctions;

export const register = (data) => (dispatch) => {
  dispatch(exportFunctions.resetRegistrationStatus());

  return dispatch(postData(`${ROUTE_REGISTER}`, data, receiveRegistration, receiveRegistrationError));
};

export const receiveCategories = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_CATEGORIES,
    data,
  });
};

export const fetchCategories = (language) => (dispatch) => {
  dispatch(exportFunctions.loadCategories());
  const usedLanguage = language ? language : i18n.language;
  dispatch(
    fetchData(
      usedLanguage === LOCALE_GERMAN ? ROUTE_CATEGORIES_GERMAN : ROUTE_CATEGORIES_ENGLISH,
      receiveCategories
    )
  );
};

export const receiveServicePackages = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_SERVICE_PACKAGES,
    data,
  });
};

export const receiveServicePackagesForUpgrade = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_SERVICE_PACKAGES_FOR_UPGRADE,
    data,
  });
};

export const fetchServicePackages = (language) => (dispatch) => {
  dispatch(exportFunctions.loadServicePackages());
  const usedLanguage = language ? language : i18n.language;
  dispatch(
    fetchData(
      usedLanguage === LOCALE_GERMAN ? ROUTE_SERVICE_PACKAGES_GERMAN : ROUTE_SERVICE_PACKAGES_ENGLISH,
      receiveServicePackages
    )
  );
};

export const fetchServicePackagesForUpgrade = () => (dispatch) => {
  dispatch(exportFunctions.loadServicePackages());
  dispatch(fetchData(ROUTE_SERVICE_PACKAGES_FOR_UPGRADE, receiveServicePackagesForUpgrade));
};
