import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import i18n from "../../../assets/Translations/i18n";
import { ScrollToTopButton } from "../../Components/ScrollToTopButton/ScrollToTopButton";
import ImprintDE from "./Imprint_de.md";
import ImprintEN from "./Imprint_en.md";

const useStyles = makeStyles({
  imprintPage: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    maxWidth: 1110,
    margin: "0 auto",
    padding: "0 20px",
    whiteSpace: "pre-line",
    maxHeight: "calc(100vh - 296px)",
  },
  button: {
    color: "#00637c",
    fontSize: "1rem",
    textTransform: "none",
  },
  hr: {
    width: "100%",
    border: "1px solid #00637c",
    borderRadius: 1,
  },
  text: {
    paddingBottom: 120,
    textAlign: "left",
  },
});

export const ImprintPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const [markdown, setMarkdown] = useState({ imprintMarkdown: "" });

  const { t } = useTranslation();

  const fetchMarkdown = async (signal) => {
    let Imprint;
    if (i18n.language === "de_DE") {
      Imprint = ImprintDE;
    } else if (i18n.language === "en_US") {
      Imprint = ImprintEN;
    }
    try {
      const res = await fetch(Imprint, { signal });
      const imprintMarkdown = await res.text();
      setMarkdown({ imprintMarkdown });
    } catch (error) {
      console.error("Error during fetching of markdown:", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    fetchMarkdown(signal);
    return () => abortController.abort();
  }, []);

  return (
    <div className={classes.imprintPage}>
      <Button
        className={classes.button}
        component="button"
        color="primary"
        type="submit"
        onClick={() => history.goBack()}
      >
        {t("back")}
      </Button>
      <hr className={classes.hr} />
      <Typography className={classes.text} component="div">
        <ReactMarkdown source={markdown.imprintMarkdown} />
      </Typography>
      <ScrollToTopButton />
    </div>
  );
};

export default ImprintPage;
