import get from "lodash/get";
import {
  RECEIVE_ACCOUNT,
  RECEIVE_ACCOUNT_USERS,
  RECEIVE_CREATE_USER,
  RECEIVE_CREATE_USER_ERROR,
  RECEIVE_DELETE_USER,
  RECEIVE_DELETE_USER_ERROR,
  RECEIVE_UPDATE_ACCOUNT,
  RECEIVE_UPDATE_ACCOUNT_ERROR,
  RECEIVE_UPDATE_USER,
  RECEIVE_UPDATE_USER_ERROR,
  RESET_CREATE_USER_ERROR,
  RESET_DELETE_USER_ERROR,
  RESET_UPDATE_ACCOUNT,
  RESET_UPDATE_USER_ERROR,
} from "./ManagerActions";
import { DELETE_TOKEN } from "../User/UserActions";

const initialState = {
  users: null,
  deleteUserError: false,
  updateUserError: false,
  updateAccountError: false,
  lastUpdateAccountError: "",
  createUserError: false,
  maxUsersReached: false,
  account: null,
};

export default function UserReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_ACCOUNT_USERS: {
      return {
        ...state,
        users: action.users,
      };
    }
    case RECEIVE_DELETE_USER: {
      return {
        ...state,
        deleteUserError: false,
      };
    }
    case RECEIVE_DELETE_USER_ERROR: {
      return {
        ...state,
        deleteUserError: true,
      };
    }
    case RESET_DELETE_USER_ERROR: {
      return {
        ...state,
        deleteUserError: false,
      };
    }
    case RECEIVE_UPDATE_USER: {
      return {
        ...state,
        updateUserError: false,
      };
    }
    case RECEIVE_UPDATE_USER_ERROR: {
      return {
        ...state,
        updateUserError: true,
      };
    }
    case RESET_UPDATE_USER_ERROR: {
      return {
        ...state,
        updateUserError: false,
      };
    }

    case RECEIVE_CREATE_USER: {
      return {
        ...state,
        createUserError: false,
        maxUsersReached: false,
      };
    }
    case RECEIVE_CREATE_USER_ERROR: {
      const { error } = action;
      const maxUsersReached = get(error, "response.status") === 422;
      return {
        ...state,
        createUserError: true,
        maxUsersReached,
      };
    }
    case RESET_CREATE_USER_ERROR: {
      return {
        ...state,
        createUserError: false,
        maxUsersReached: false,
      };
    }

    case RECEIVE_ACCOUNT: {
      const { account } = action;

      return {
        ...state,
        account,
      };
    }

    case RECEIVE_UPDATE_ACCOUNT: {
      const { account } = action;
      return {
        ...state,
        updateAccountError: false,
        accountUpdated: true,
        account,
      };
    }
    case RECEIVE_UPDATE_ACCOUNT_ERROR: {
      const { response } = action;
      const lastUpdateAccountError = get(response, "data", "");
      return {
        ...state,
        updateAccountError: true,
        lastUpdateAccountError,
        accountUpdated: false,
      };
    }
    case RESET_UPDATE_ACCOUNT: {
      return {
        ...state,
        updateAccountError: false,
        lastUpdateAccountError: "",
        accountUpdated: false,
      };
    }
    case DELETE_TOKEN:
      return {
        ...state,
        account: null
      };
    default:
      return state;
  }
}
