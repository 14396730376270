import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Numeral from "numeral";
import { PRICE_FORMAT } from "../../../Core/Constants";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import filter from "lodash/filter";
import { CircularProgress, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import get from "lodash/get";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

import { resetUpdateAccount, updateAccount, getAccount } from "../../../Reducers/Manager/ManagerActions";
import {
  fetchCategories,
  fetchServicePackagesForUpgrade
} from "../../../Reducers/Registration/RegistrationActions";
import { showMessage } from "../Notifications/Notifications";
import { ERROR_UPDATE_ACCOUNT_TOO_MANY_USERS_FOR_OPERATION } from "../../../Core/Constants";

const useStyles = makeStyles((theme) => ({
  servicePackage: {
    cursor: "pointer",
    minHeight: 250,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardHeaderSelected: {
    backgroundColor: "#00637c",
    color: "#ffffff",
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  totalPriceContent: {
    marginTop: 32,
    flex: 1,
  },
  submitButtonContent: {
    paddingBottom: 72,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: 16,
  },
  loadingSpinner: { marginTop: 30 },
  servicePackageContent: {
    flex: 1,
    padding: theme.spacing(4, 0, 3),
  },
}));

export const Pricing = () => {
  const { t } = useTranslation("pricing");
  const { account, accountUpdated, updateAccountError, lastUpdateAccountError } = useSelector(
    (state) => state.Manager
  );
  const { categories, servicePackagesForUpgrade } = useSelector((state) => state.Registration);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedServicePackage, setSelectedServicePackage] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (!servicePackagesForUpgrade) {
      setIsLoading(true);
      dispatch(fetchServicePackagesForUpgrade());
    }
    if (!account) {
      dispatch(getAccount());
    }
    if (!categories) {
      setIsLoading(true);
      dispatch(fetchCategories());
    }
    if (servicePackagesForUpgrade && categories && account && !selectedServicePackage && !selectedCategories) {
      const initialServicePackage = get(account, "servicePackage");
      const initialCategories = get(account, "categories");
      setSelectedServicePackage(initialServicePackage);
      setSelectedCategories(initialCategories);
      setIsLoading(false);
    }
    if (updateAccountError) {
      const initialServicePackage = get(account, "servicePackage");
      const initialCategories = get(account, "categories");
      setSelectedServicePackage(initialServicePackage);
      setSelectedCategories(initialCategories);
      setIsLoading(false);
      showMessage(
        lastUpdateAccountError === ERROR_UPDATE_ACCOUNT_TOO_MANY_USERS_FOR_OPERATION
          ? t("errorTooManyUsers")
          : t("errorUpdatingAccount"),
        4000
      );
      dispatch(resetUpdateAccount());
    }
    if (accountUpdated) {
      const initialServicePackage = get(account, "servicePackage");
      const initialCategories = get(account, "categories");
      setSelectedServicePackage(initialServicePackage);
      setSelectedCategories(initialCategories);
      setIsLoading(false);
      showMessage(t("accountSuccessfullyUpdated"), 4000);
      dispatch(resetUpdateAccount());
    }
  }, [
    account,
    accountUpdated,
    categories,
    dispatch,
    selectedCategories,
    selectedServicePackage,
    servicePackagesForUpgrade,
    t,
    updateAccountError,
    lastUpdateAccountError,
  ]);

  const submitSaveServicePackage = () => {
    setIsLoading(true);
    const servicePackageId = get(selectedServicePackage, "id");
    const organizationName = get(account, "organizationName");
    const categoriesArray = selectedCategories.map((category) => category.id);
    dispatch(updateAccount(servicePackageId, categoriesArray, organizationName));
  };

  const onSelectPackage = (servicePackage) => {
    setSelectedServicePackage(servicePackage);
  };

  const categoryIsSelected = (categoryId) => {
    return !!find(selectedCategories, ["id", categoryId]);
  };

  const handleCategoryChange = (category) => {
    const checked = categoryIsSelected(category.id);
    let newSelectedCategories = [...selectedCategories];
    if (!checked) {
      newSelectedCategories.push(category);
    } else {
      newSelectedCategories = filter(newSelectedCategories, (c) => c.id !== category.id);
    }
    setSelectedCategories(newSelectedCategories);
  };

  const buttonIsDisabled =
    (get(selectedServicePackage, "id") === get(account, "servicePackage.id") &&
      isEqual(selectedCategories, get(account, "categories"))) ||
    isEmpty(selectedCategories);

  return (
    <div>
      <Container maxWidth="md" component="main">
        {isLoading && <CircularProgress className={classes.loadingSpinner} />}

        {!isLoading && (
          <>
            <Container maxWidth="xs" component="main" className={classes.servicePackageContent}>
              <Typography component="h2" variant="h2" align="center" color="textPrimary" gutterBottom>
                {t("headlineServicePackage")}
              </Typography>
            </Container>
            <Grid container spacing={5} alignItems="flex-end">
              {servicePackagesForUpgrade.map((servicePackage) => (
                <Grid
                  item
                  xs={12}
                  sm={servicePackage.id === selectedServicePackage && selectedServicePackage.id ? 12 : 6}
                  md={4}
                  key={servicePackage.id}
                  onClick={() => onSelectPackage(servicePackage)}
                >
                  <Card className={classes.servicePackage}>
                    <CardHeader
                      title={servicePackage.title}
                      subheader={servicePackage.subheader}
                      titleTypographyProps={{ align: "center" }}
                      subheaderTypographyProps={{ align: "center" }}
                      className={
                        selectedServicePackage && selectedServicePackage.id === servicePackage.id
                          ? classes.cardHeaderSelected
                          : classes.cardHeader
                      }
                    />
                    <CardContent>
                      <div className={classes.cardPricing}>
                        <Typography component="h2" variant="h3" color="textPrimary">
                          {Numeral(servicePackage.price).format(PRICE_FORMAT)}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                          {t("perMonth")}
                        </Typography>
                      </div>
                      <div>
                        <Typography>{servicePackage.description}</Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            {/**
             @todo: disabled, cf ELI-354
            <Container maxWidth="xs" component="main" className={classes.servicePackageContent}>
              <Typography component="h2" variant="h2" align="center" color="textPrimary" gutterBottom>
                {t("headlineCategories")}
              </Typography>
            </Container>
            <FormControl component="fieldset">
              {categories.map((category) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categoryIsSelected(category.id) || false}
                        onChange={() => handleCategoryChange(category)}
                      />
                    }
                    key={`formControl_${category.id}`}
                    label={`${category.name} (${Numeral(category.price).format(PRICE_FORMAT)})`}
                  />
                );
              })}
            </FormControl>
            {isEmpty(selectedCategories) && (
              <Typography>Es muss mindestens eine Kategorie ausgewählt werden</Typography>
            )}
             */}

            <Container component="main" maxWidth="xs" className={classes.submitButtonContent}>
              <Tooltip title={buttonIsDisabled ? t("buttonDisabledHint") : ""} placement="top">
                <span>
                  <Button
                    disabled={buttonIsDisabled}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={submitSaveServicePackage}
                    className={classes.submit}
                  >
                    {t("changePlan")}
                  </Button>
                </span>
              </Tooltip>
            </Container>
          </>
        )}
      </Container>
    </div>
  );
};

export default Pricing;
