import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import { CircularProgress, Grid, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccount,
  resetUpdateAccount,
  updateAccountAddress,
} from "../../../Reducers/Manager/ManagerActions";
import Button from "@material-ui/core/Button";
import { showMessage } from "../Notifications/Notifications";
import get from "lodash/get";

export const AccountData = () => {
  const { t } = useTranslation("accountData");
  const dispatch = useDispatch();

  const { account, updateAccountError, accountUpdated } = useSelector((state) => state.Manager);

  const [isLoading, setIsLoading] = useState(true);

  const [street, setStreet] = useState("");
  const [addressSuffix, setAddressSuffix] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {
    if (!account) {
      setIsLoading(true);
      dispatch(getAccount());
    } else {
      setIsLoading(false);
      setStreet(account.billingAddress.street);
      setAddressSuffix(account.billingAddress.addressSuffix);
      setPostalCode(account.billingAddress.postalCode);
      setPostalCode(account.billingAddress.postalCode);
      setCity(account.billingAddress.city);
      setCountry(account.billingAddress.country);
    }
    if (updateAccountError) {
      showMessage(t("errorUpdatingAccount"), 4000);
      dispatch(resetUpdateAccount());
    }
    if (accountUpdated) {
      setIsLoading(false);
      showMessage(t("accountSuccessfullyUpdated"), 4000);
      dispatch(resetUpdateAccount());
    }
  }, [account, updateAccountError]);

  const onClickSave = () => {
    const billingAddress = {
      street,
      addressSuffix,
      postalCode,
      city,
      country,
    };
    setIsLoading(true);
    dispatch(updateAccountAddress(billingAddress));
  };

  return (
    <div style={{ marginTop: 32 }}>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <Container maxWidth="md" component="main">
          <Paper style={{ padding: 16 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="h4" variant="h4">
                  {t("address")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setStreet(e.target.value)}
                  variant="outlined"
                  required
                  fullWidth
                  id="street"
                  label={t("street")}
                  name="street"
                  value={street}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setAddressSuffix(e.target.value)}
                  variant="outlined"
                  required
                  fullWidth
                  id="addressSuffix"
                  label={t("addressSuffix")}
                  name="addressSuffix"
                  value={addressSuffix}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setPostalCode(e.target.value)}
                  variant="outlined"
                  required
                  fullWidth
                  type={"number"}
                  id="postalCode"
                  label={t("postalCode")}
                  name="postalCode"
                  value={postalCode}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setCity(e.target.value)}
                  variant="outlined"
                  required
                  fullWidth
                  id="city"
                  label={t("city")}
                  name="city"
                  value={city}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setCountry(e.target.value)}
                  variant="outlined"
                  required
                  fullWidth
                  id="country"
                  label={t("country")}
                  name="country"
                  value={country}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary" onClick={onClickSave}>
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      )}
    </div>
  );
};

export default AccountData;
