import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { Slider } from "@material-ui/core";
import "./Filter.css";
import { setFilter } from "../../../Reducers/Product/ProductActions";

const propTypes = {
  filter: PropTypes.objectOf(PropTypes.any),
};
const defaultProps = {
  filter: null,
};

const FilterSlider = (props) => {
  const { filter } = props;

  const dispatch = useDispatch();

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  let { value } = filter;
  if (!value) {
    value = [filter.min, filter.max];
  }
  const [lower, upper] = value;

  if (lower == null || upper == null) {
    console.error("slider expected [min, max] value; got: ", JSON.stringify(value));
  }
  const sanitized = [filter.min, filter.max];

  if (filter.min <= lower) sanitized[0] = lower;
  else console.error(`Slider: Lower value too small: ${lower}`);

  if (filter.max >= upper && upper >= lower) sanitized[1] = upper;
  else console.error("Slider: Upper value too high, or lower than lower");

  const [debouncedCallback] = useDebouncedCallback(() => {
    dispatch(setFilter(filter));
  }, 500);

  const handleChange = (event, newValue) => {
    if (filter.value !== newValue) {
      filter.value = [...newValue];
      // we need to force a re-render here so the Slider gets updated
      debouncedCallback();
      forceUpdate();
    }
  };

  const marks = [
    {
      value: filter.min,
      label: filter.min,
    },
    {
      value: filter.max,
      label: filter.max,
    },
  ];

  return (
    <Slider
      value={sanitized}
      onChange={handleChange}
      valueLabelDisplay="auto"
      marks={marks}
      aria-labelledby="range-slider"
      min={filter.min}
      max={filter.max}
    />
  );
};

FilterSlider.propTypes = propTypes;
FilterSlider.defaultProps = defaultProps;

export default React.memo(FilterSlider);
