import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import filter from "lodash/filter";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Numeral from "numeral";
import Actions, {
  fetchCategories,
  fetchServicePackages,
  register,
} from "../../../Reducers/Registration/RegistrationActions";
import { PRICE_FORMAT } from "../../../Core/Constants";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../../assets/logo_without_claim.svg";
import qs from "qs";
import { LOCALE_ENGLISH, LOCALE_GERMAN } from "../../../assets/Translations/i18n";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  page: {
    flex: 1,
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  logo: {
    height: "2rem",
    position: "absolute",
    left: "2rem",
    top: "1.5rem",
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  servicePackageContent: {
    flex: 1,
    padding: theme.spacing(8, 0, 6),
  },
  categoryContent: {
    flex: 1,
    paddingBottom: 64,
    marginTop: 64,
  },
  servicePackage: {
    cursor: "pointer",
    minHeight: 250,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardHeaderSelected: {
    backgroundColor: "#00637c",
    color: "#ffffff",
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  totalPriceContent: {
    marginTop: 32,
    flex: 1,
  },
  submitButtonContent: {
    paddingBottom: 72,
  },
  successMessage: {
    fontSize: "1.5rem",
  },
  alert: {
    margin: 16,
    padding: 16,
    backgroundColor: "#ff0000",
    color: "#ffffff",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: 16,
  },
  contractDurationText: {
    marginTop: 10,
  },
}));

export const RegistrationPage = () => {
  const { t } = useTranslation("register");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [price, setPrice] = useState(0);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [street, setStreet] = useState("");
  const [addressSuffix, setAddressSuffix] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVerify, setPasswordVerify] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordVerifyError, setPasswordVerifyError] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedServicePackage, setSelectedServicePackage] = useState(undefined);
  const [termsChecked, setTermsChecked] = useState(false);
  const {
    categories,
    categoriesLoaded,
    servicePackages,
    servicePackagesLoaded,
    registrationSuccessful,
    registrationError,
    registrationErrorMessage,
  } = useSelector((state) => state.Registration);
  const isLoadingInitially = !servicePackagesLoaded || !categoriesLoaded;
  const [isLoading, setIsLoading] = useState(isLoadingInitially);
  const history = useHistory();
  let location = useLocation();
  const languageSetViaUrl = qs.parse(location.search, { ignoreQueryPrefix: true }).lng;

  const buttonIsDisabled =
    emailError ||
    isEmpty(selectedCategories) ||
    isEmpty(selectedServicePackage) ||
    lastName === "" ||
    firstName === "" ||
    email === "" ||
    organizationName === "" ||
    password !== passwordVerify ||
    password.length < 8 ||
    !termsChecked;

  useEffect(() => {
    if (!categories) {
      setIsLoading(true);
      dispatch(fetchCategories(languageSetViaUrl));
    }
    if (!servicePackages) {
      setIsLoading(true);
      dispatch(fetchServicePackages(languageSetViaUrl));
    }
    if (servicePackages && categories) {
      // set all categories and their respective price, cf. ELI-354
      setSelectedCategories(categories);
      setIsLoading(false);
    }
  }, [categories, dispatch, servicePackages]);

  // disabled, cf. ELI-354
  const handleCategoryChange = (category) => {
    let newPrice = price;
    const categoryPrice = get(category, "price");
    let newSelectedCategories = selectedCategories;
    if (category.checked === undefined) {
      category.checked = true;
      newSelectedCategories.push(category);
      newPrice += categoryPrice;
    } else if (category.checked === false) {
      category.checked = true;
      newSelectedCategories.push(category);
      newPrice += categoryPrice;
    } else {
      category.checked = false;
      newSelectedCategories = filter(newSelectedCategories, (c) => c.id !== category.id);
      newPrice -= categoryPrice;
    }
    setPrice(newPrice);
    setSelectedCategories(newSelectedCategories);
  };

  const handleTextChange = (text, type) => {
    if (type === "email") {
      setEmail(text);
    } else if (type === "firstName") {
      setFirstName(text);
    } else if (type === "lastName") {
      setLastName(text);
    } else if (type === "organizationName") {
      setOrganizationName(text);
    } else if (type === "street") {
      setStreet(text);
    } else if (type === "addressSuffix") {
      setAddressSuffix(text);
    } else if (type === "postalCode") {
      setPostalCode(text);
    } else if (type === "city") {
      setCity(text);
    } else if (type === "country") {
      setCountry(text);
    } else if (type === "password") {
      setPassword(text);
    } else if (type === "passwordVerify") {
      setPasswordVerify(text);
    }
  };

  const onSelectPackage = (servicePackage) => {
    let newPrice = price;
    if (selectedServicePackage) {
      newPrice -= selectedServicePackage.price;
    }
    newPrice += servicePackage.price;
    setPrice(newPrice);
    setSelectedServicePackage(servicePackage);
  };

  useEffect(() => {
    if (!selectedServicePackage && servicePackages && servicePackages.length > 0) {
      onSelectPackage(servicePackages[0]);
    }
  }, [onSelectPackage, selectedServicePackage, servicePackages]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (registrationSuccessful) {
      setIsLoading(false);
      const navigateToLandingPage = setTimeout(() => history.push("/"), 5000);
      return () => {
        dispatch(Actions.resetRegistrationStatus());
        clearTimeout(navigateToLandingPage);
      };
    } else if (registrationError) {
      setIsLoading(false);
    }
  }, [dispatch, history, registrationSuccessful, registrationError]);

  const submitRegistration = () => {
    setIsLoading(true);

    const categoryData = [];
    categories.forEach((c) => categoryData.push(c.id));

    const data = {
      password,
      givenName: firstName,
      familyName: lastName,
      organizationName,
      email,
      servicePackageId: selectedServicePackage.id,
      categories: categoryData, // id list
      billingAddress: {
        street,
        addressSuffix,
        postalCode,
        city,
        country,
      },
    };
    dispatch(register(data));
  };

  const onBlurEmail = () => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = emailRegex.test(String(email).toLowerCase());
    setEmailError(!valid);
  };

  const onBlurPassword = () => {
    setPasswordError(password.length < 8);
  };

  const onBlurPasswordVerify = () => {
    const error = passwordVerify !== "" && password !== passwordVerify;
    setPasswordVerifyError(error);
  };

  return (
    <div className={classes.page}>
      <CssBaseline />
      {isLoading && <CircularProgress />}
      {!registrationSuccessful && !isLoading && (
        <>
          <Container component="main" maxWidth="md">
            {registrationError && (
              <div className={classes.alert}>
                <Typography>{registrationErrorMessage}</Typography>
              </div>
            )}
            <img className={classes.logo} src={logo} alt="logo" />
            <div className={classes.paper}>
              <Typography component="h1" variant="h2" gutterBottom>
                {t("registerHeadline")}
              </Typography>
              <Grid container justify="center" spacing={2}>
                <Grid item>
                  <Link href="/login" variant="body2">
                    {t("alreadyRegisteredLoginNow")}
                  </Link>
                  <br />
                  <br />
                </Grid>
              </Grid>
              <Typography variant="h5" align="center" color="textSecondary" component="p">
                {t("registerIntro")}
              </Typography>
            </div>
          </Container>
          <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
              <Typography component="h4" variant="h4">
                {t("contactData")}
              </Typography>
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      onChange={(e) => handleTextChange(e.target.value, "organizationName")}
                      variant="outlined"
                      required
                      fullWidth
                      id="organizationName"
                      helperText={t("pleaseEnterDiscountCode")}
                      label={t("organizationName")}
                      name="organizationName"
                      value={organizationName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={(e) => handleTextChange(e.target.value, "firstName")}
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label={t("firstName")}
                      autoFocus
                      value={firstName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={(e) => handleTextChange(e.target.value, "lastName")}
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label={t("lastName")}
                      name="lastName"
                      value={lastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      onChange={(e) => handleTextChange(e.target.value, "email")}
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label={t("email")}
                      name="email"
                      autoComplete="email"
                      onBlur={onBlurEmail}
                      error={emailError}
                      value={email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      onChange={(e) => handleTextChange(e.target.value, "password")}
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label={t("password")}
                      type="password"
                      id="password"
                      onBlur={onBlurPassword}
                      error={passwordError}
                      value={password}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      onChange={(e) => handleTextChange(e.target.value, "passwordVerify")}
                      error={passwordVerifyError}
                      variant="outlined"
                      required
                      fullWidth
                      name="password-verify"
                      label={t("passwordRepeat")}
                      type="password"
                      id="password-verify"
                      onBlur={onBlurPasswordVerify}
                      value={passwordVerify}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="h4" variant="h4">
                      {t("address")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      onChange={(e) => handleTextChange(e.target.value, "street")}
                      variant="outlined"
                      required
                      fullWidth
                      id="street"
                      label={t("street")}
                      name="street"
                      value={street}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      onChange={(e) => handleTextChange(e.target.value, "addressSuffix")}
                      variant="outlined"
                      fullWidth
                      id="addressSuffix"
                      label={t("addressSuffix")}
                      name="addressSuffix"
                      value={addressSuffix}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      onChange={(e) => handleTextChange(e.target.value, "postalCode")}
                      variant="outlined"
                      required
                      fullWidth
                      type={"number"}
                      id="postalCode"
                      label={t("postalCode")}
                      name="postalCode"
                      value={postalCode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      onChange={(e) => handleTextChange(e.target.value, "city")}
                      variant="outlined"
                      required
                      fullWidth
                      id="city"
                      label={t("city")}
                      name="city"
                      value={city}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      onChange={(e) => handleTextChange(e.target.value, "country")}
                      variant="outlined"
                      required
                      fullWidth
                      id="country"
                      label={t("country")}
                      name="country"
                      value={country}
                    />
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
          <Container maxWidth="xs" component="main" className={classes.servicePackageContent}>
            {/*<Typography component="h2" variant="h2" align="center" color="textPrimary" gutterBottom>
              {t("headlineServicePackage")}
            </Typography>*/}
            <Typography component="h4" variant="h4">
              {t("subheadServicePackage")}
            </Typography>
          </Container>
          <Container maxWidth="md" component="main">
            <Grid container spacing={5} alignItems="flex-end" style={{ justifyContent: "center" }}>
              {servicePackages.map((servicePackage) => (
                <Grid
                  item
                  xs={12}
                  sm={servicePackage.id === selectedServicePackage && selectedServicePackage.id ? 12 : 6}
                  md={4}
                  key={servicePackage.id}
                  onClick={() => onSelectPackage(servicePackage)}
                >
                  <Card className={classes.servicePackage}>
                    <CardHeader
                      title={servicePackage.title}
                      subheader={servicePackage.subheader}
                      titleTypographyProps={{ align: "center" }}
                      subheaderTypographyProps={{ align: "center" }}
                      className={
                        selectedServicePackage && selectedServicePackage.id === servicePackage.id
                          ? classes.cardHeaderSelected
                          : classes.cardHeader
                      }
                    />
                    <CardContent>
                      <div className={classes.cardPricing}>
                        <Typography component="h2" variant="h3" color="textPrimary">
                          {Numeral(servicePackage.price).format(PRICE_FORMAT)}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                          {t("perMonth")}
                        </Typography>
                      </div>
                      <div className={classes.cardContent}>
                        <Typography>{servicePackage.description}</Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>

          <Container maxWidth="md" component="main" className={classes.contractDurationText}>
            <p>{t("contractDurationText")}</p>
          </Container>

          {/**
           @todo: disabled cf. ELI-354
          <Container component="main" maxWidth="sm" className={classes.categoryContent}>
            <Typography component="h2" variant="h2" align="center" color="textPrimary" gutterBottom>
              {t("headlineCategories")}
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" component="p" gutterBottom>
              {t("subheadCategories")}
            </Typography>
              <FormControl component="fieldset">
                {categories.map((category) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={category.checked || false}
                        onChange={() => handleCategoryChange(category)}
                      />
                    }
                    key={`formControl_${category.id}`}
                    label={`${category.name} (${Numeral(category.price).format(PRICE_FORMAT)})`}
                  />
                ))}
              </FormControl>
          </Container>
           */}
          <Container component="main" maxWidth="l" className={classes.totalPriceContent}>
            {/** <Typography component="h2" variant="h2" align="center" color="textPrimary" gutterBottom>
              {t("totalPrice")}
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              component="p"
              style={{ marginBottom: 16 }}
            >
              {price > 0 ? Numeral(price).format(PRICE_FORMAT) : "-"}
            </Typography>*/}
            <FormControlLabel
              control={<Checkbox checked={termsChecked} onChange={() => setTermsChecked(!termsChecked)} />}
              label={
                <Typography>
                  <span dangerouslySetInnerHTML={{ __html: t("iAcceptTheTermsandConditons") }} />
                </Typography>
              }
            />
          </Container>
          <Container component="main" maxWidth="xs" className={classes.submitButtonContent}>
            <Tooltip title={buttonIsDisabled ? t("buttonDisabledHint") : ""} placement={"top"}>
              <span>
                <Button
                  disabled={buttonIsDisabled}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={submitRegistration}
                  className={classes.submit}
                >
                  {t("orderNow")}
                </Button>
              </span>
            </Tooltip>
          </Container>
        </>
      )}
      {registrationSuccessful && (
        <Container component="main" maxWidth="xs" className={classes.submitButtonContent}>
          <Typography className={classes.successMessage}>{t("orderSuccessfulText")}</Typography>
        </Container>
      )}
    </div>
  );
};
