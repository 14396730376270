import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import FilterListMemoized from "../../Components/FilterList/FilterList";
import ProductMemoized from "../../Components/Product/Product";
import "./OverviewPage.css";
import ProductActions, {
  setProductSearchParam,
  resetFilters,
  setUserSelectedReferenceDate,
} from "../../../Reducers/Product/ProductActions";
import { CATEGORY_SELECTION } from "../../App";
import SearchForm from "../../Components/SearchForm/SearchForm";
import TrialAccountNotice from "../../Components/TrialAccountNotice/TrialAccountNotice";
import { getAccount } from "../../../Reducers/Manager/ManagerActions";

export const OverviewPage = () => {
  const productContainer = useRef(null);
  const products = useSelector((state) => state.Product.products);
  const userLanguage = useSelector((state) => state.User.user.language);
  const productsLoaded = useSelector((state) => state.Product.productsLoaded);
  const filters = useSelector((state) => state.Product.filters);
  const categories = useSelector((state) => state.Product.categories);
  const filtersLoaded = useSelector((state) => state.Product.filtersLoaded);
  const fetchingFilters = useSelector((state) => state.Product.fetchingFilters);
  const fetchingProducts = useSelector((state) => state.Product.fetchingProducts);
  const activeFilters = useSelector((state) => state.Product.activeFilters);
  const activeCategory = useSelector((state) => state.Product.activeCategory);
  const { account } = useSelector((state) => state.Manager);
  const singleCategoryUser = categories.length === 1;
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState();
  const [expandedView, setExpandedView] = useState(false);
  const [collapsedManufacturers, setCollapsedManufacturers] = useState([]);

  const { t } = useTranslation("overview");

  const onSelectRow = (selRow) => {
    if (selectedRow === selRow) {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(selRow);
    }
  };

  const resetFiltersAndFetchProducts = async () => {
    await dispatch(resetFilters());
    dispatch(ProductActions.fetchProducts());
  };

  const navigateToCategorySelection = useCallback(() => {
    // reset userSelectedReferenceDate
    dispatch(setUserSelectedReferenceDate(null));
    history.push(`/${CATEGORY_SELECTION}`);
  }, [dispatch, history]);

  useEffect(() => {
    if (!activeCategory) {
      navigateToCategorySelection();
    }
    if (!account) {
      dispatch(getAccount());
    }
  }, [activeCategory, navigateToCategorySelection, account]);

  if (!filtersLoaded && !fetchingFilters && activeCategory) {
    dispatch(ProductActions.fetchFilters());
  }
  if (!productsLoaded && !fetchingProducts && activeCategory) {
    // initial fetch of products
    dispatch(ProductActions.fetchProducts(activeFilters));
  }

  const onExpandView = () => {
    setExpandedView(!expandedView);
  };

  const getProductsWithQuery = async (productSearchParam) => {
    await dispatch(setProductSearchParam(productSearchParam));
    dispatch(ProductActions.fetchProducts(activeFilters));
  };

  const collapseProductsBy = (manufacturerName) => {
    setCollapsedManufacturers(
      collapsedManufacturers.includes(manufacturerName)
        ? collapsedManufacturers.filter((name) => name !== manufacturerName)
        : [...collapsedManufacturers, manufacturerName]
    );
  };

  const filtersEmpty = filters && filters.length === 0;
  const productsEmpty = products && products.length === 0;

  const showNoFiltersLabel = filtersLoaded && filtersEmpty;
  const showNoResultsLabel = productsLoaded && productsEmpty;

  const isTrialAccount = account && account.servicePackage.type === "trial";

  const filteredProducts = products
    .filter(
      (product, index, self) =>
        // find those products whose manufacturer is not collapsed
        !collapsedManufacturers.includes(product.subtitle) ||
        // or those that are the first occurrence of a product from a given manufacturer
        self.findIndex((el) => el.subtitle === product.subtitle) === index
    )
    .map((product) => ({
      ...product,
      isCollapsed: collapsedManufacturers.includes(product.subtitle),
      manufacturerGroupSize: products.filter((p) => p.subtitle === product.subtitle).length,
    }));

  return (
    <div className="OverviewPage">
      <div className="OverviewPage-header">
        {filtersLoaded && (
          <div
            className={
              singleCategoryUser
                ? "OverviewPage-buttonContainer-singleCategory"
                : "OverviewPage-buttonContainer"
            }
          >
            <div className="OverviewPage-buttonInnerContainer">
              {!singleCategoryUser && (
                <Button
                  component="button"
                  style={{
                    color: "#ffffff",
                    fontSize: "0.8rem",
                    marginBottom: 4,
                    paddingTop: 8,
                    paddingBottom: 8,
                    fontFamily: "Ubuntu, sans-serif",
                    margin: "1px",
                  }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={navigateToCategorySelection}
                >
                  {t("backToCategorySelection")}
                </Button>
              )}
              {!filtersEmpty && (
                <>
                  <Button
                    component="button"
                    style={{
                      color: "#ffffff",
                      fontSize: ".8rem",
                      marginBottom: 4,
                      paddingTop: 8,
                      paddingBottom: 8,
                      fontFamily: "Ubuntu, sans-serif",
                      margin: "1px",
                    }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={resetFiltersAndFetchProducts}
                  >
                    {t("resetFilter")}
                  </Button>
                </>
              )}
            </div>
            {!filtersEmpty && <SearchForm onSubmit={getProductsWithQuery} />}
          </div>
        )}
      </div>
      {productsLoaded && (
        <div className="OverviewPage-headerMain">
          <div className="OverviewPage-productLength">{t("amountProducts", { amount: products.length })}</div>
        </div>
      )}
      <aside className={!filtersLoaded ? "OverviewPage-aside loading" : "OverviewPage-aside"}>
        {isTrialAccount && <TrialAccountNotice />}
        <FilterListMemoized
          filters={filters}
          filtersLoaded={filtersLoaded}
          showNoFiltersLabel={showNoFiltersLabel}
          filtersEmpty={filtersEmpty}
        />
      </aside>
      <main
        ref={productContainer}
        className={!productsLoaded ? "OverviewPage-main loading" : "OverviewPage-main"}
      >
        {productsLoaded && !productsEmpty && (
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
            }}
          >
            {filteredProducts.map((product) => (
              <Grid
                key={product.identifier}
                item
                style={{
                  flex: "0 0 22.5%",
                  margin: 0,
                }}
              >
                <ProductMemoized
                  container={productContainer}
                  userLanguage={userLanguage}
                  productIdentifier={product.identifier}
                  key={product.id}
                  productId={product.id}
                  isNew={product.isNew}
                  title={product.title}
                  subtitle={product.subtitle}
                  image={product.image}
                  primaryGroups={product.primaryGroups}
                  secondaryGroups={product.secondaryGroups}
                  selectedRow={selectedRow}
                  onSelectRow={onSelectRow}
                  expandedView={expandedView}
                  onExpandView={onExpandView}
                  collapseProductsBy={collapseProductsBy}
                  isCollapsed={product.isCollapsed}
                  manufacturerGroupSize={product.manufacturerGroupSize}
                  countryIcon={product.countryIcon}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {!productsLoaded && <CircularProgress />}
        {showNoResultsLabel && <div className="NoResultsLabel">{t("noProductFound")}</div>}
      </main>
    </div>
  );
};

export default OverviewPage;
