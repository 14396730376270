export default {
  common: {
    marketOverview: "Market Overview",
    yes: "yes",
    changePassword: "Change password",
    back: "Back",
    contactUsSubject: "Request Market Overview",
  },
  entry: {
    description: "The digital market overview for wallboxes and charging stations.",
    subDescription: "Filter and search products by all relevant criteria.",
    requestAccess: "Register",
    alreadyCustomer: "You already have an account? ",
    toLogin: "Log in here.",
  },
  login: {
    email: "E-Mail",
    password: "Password",
    login: "login",
    missingParameters: '"username" and "password" required',
    invalidCombination: "Invalid username and password combination",
  },
  register: {
    registerHeadline: "Register",
    registerIntro:
      "By registering with cosmix - Europe's largest platform for charging infrastructure, you get access to a digital market overview with hundreds of models of wall boxes and charging stations in the AC and DC sector.",
    firstName: "Name",
    lastName: "Surname",
    company: "Company",
    pleaseEnterDiscountCode: "Please enter discount code after company",
    organizationName: "Company",
    email: "Email",
    password: "Password (min. 8 Characters)",
    passwordRepeat: "Repeat password",
    address: "Address",
    street: "Address Line",
    addressSuffix: "Email for invoice",
    postalCode: "Postcode",
    city: "City",
    country: "Country",
    alreadyRegisteredLoginNow: "You already have an account? Log in here",
    contactData: "Contact",
    headlineServicePackage: "Service Package",
    subheadServicePackage: "Select a package for your company",
    headlineCategories: "Categories",
    subheadCategories: "Select one or more categories",
    totalPrice: "Total price",
    iAcceptTheTermsandConditons:
      'I accept the <a href="/terms" target="_blank">terms & conditions</a> of the eliso GmbH',
    orderNow: "register now",
    buttonDisabledHint: "Please enter all required fields",
    perMonth: "/ month",
    contractDurationText:
      "All prices are displayed without VAT. Contract duration: 12 month contract with automatic extension, if not canceled before the end of the contract.",
    orderSuccessfulText:
      "Thanks for signing up. We will check the activation of your account now and we will contact you about the next steps.",
  },
  categories: {
    chooseCategory: "Please select your main category",
    categoriesEmptyMessage: "No categories found. Please try again later.",
  },
  changePassword: {
    currentPassword: "Current password",
    passwordNotCorrect: "Password not correct",
    newPassword: "New password",
    requirements: "Requirements: At least 8 characters",
    requirementsNotMatching: "Password does not match the requirements",
    confirmPassword: "Please confirm your password",
    passwordNotMatching: "...does not yet match",
    passwordUpdateSuccessful: "Password change successful",
    ok: "OK",
  },
  overview: {
    backToCategorySelection: "Back to Category Selection",
    resetFilter: "Reset Filters",
    noFilterFound: "Unfortunately no filters were found for the selected category.",
    noProductFound: "Unfortunately no products were found for your filter combination.",
    amountProducts: "Results: {{amount}}",
  },
  userMenu: {
    loggedInAs: "Logged in as:",
    logOut: "Log out",
    german: "German",
    english: "English",
    manageAccount: "Manage account",
  },
  manageAccount: {
    backToOverview: "Back to overview",
    user: "Users",
    pricePlan: "Price Plan",
    accountData: "Company Data",
  },
  accountData: {
    address: "Address",
    street: "Address Line 1",
    addressSuffix: "Email for invoice",
    postalCode: "Postcode",
    city: "City",
    country: "Country",
    save: "Save",
    accountSuccessfullyUpdated: "Your data was successfully saved.",
    errorUpdatingAccount: "An error occurred during account update. Please try again later.",
  },
  userList: {
    firstName: "First Name",
    lastName: "Last Name",
    isManager: "is Manager",
    isActive: "is Active ",
    tableHeader: "Overview of all users",
    search: "Search",
    noEntries: "No entries found",
    labelDisplayedRows: "{from}-{to} of {count}",
    labelRowsSelect: "Rows",
    labelRowsPerPage: "Rows per page:",
    firstPage: "First Page",
    previousPage: "Previous Page",
    nextPage: "Next Page",
    lastPage: "Last Page",
    editUser: "Edit user",
    deleteUser: "Delete user",
    addUser: "Add user",
    deleteUserDescription: "Do you really want to delete the user {{givenName}} {{familyName}} ({{email}})?",
    cancel: "Abbrechen",
    delete: "Löschen",
    errorDeletingUser: "User could not be deleted. Please try again later.",
    errorUpdatingUser: "User could not be updated. Please try again later.",
    errorCreatingUser: "User could not be created. Please try again later.",
    maxUsersReachedError:
      "You have already reached the maximum amount of users included in your service package.",
    name: "Name:  ",
    email: "Email:  ",
    german: "German",
    english: "English",
    language: "Language",
    newUserEmail: "Email",
  },
  pricing: {
    changePlan: "Change plan",
    headlineServicePackage: "Service-Package",
    headlineCategories: "Categories",
    errorUpdatingAccount: "Your Pricing Plan could not be updated. Please try again later.",

    errorTooManyUsers:
      "Your users exceed the maximum amount of users included in the service package you selected.",
    accountSuccessfullyUpdated: "Pricing plan successfully updated.",
    perMonth: "/ Month",
  },
  product: {
    no: "no",
    showAllProperties: "Show all properties",
    showPrimaryOnly: "Display primary properties only",
    beforeYes: "before: yes",
    beforeNo: "before: no",
    newYes: "new: yes",
    newNo: "new: no",
    before: "before:",
    new: "new:",
    requestProductButton: "Product request",
    requestService: "Service request",
    requestServiceNotice:
        "How can we be of assistance?\ne.g. planning of charging infrastructure\ne.g. installation of charging infrastructure\ne.g. operating & billing of charging infrastructure",
    requestProduct: "Product request",
    requestProductNotice:
        "Do you have questions regarding the product?\ne.g. Features\ne.g. area of application\ne.g. price",
    requestProductSuccess: "Product request sent.",
    requestProductError: "An error occurred. Please try again later.",
    collapseProducts: "Collapse products by {{manufacturer}}",
    extendProducts: "extend {{count}} products by {{manufacturer}}",
    displayProductInformation: "Product details",
  },
  productChangesMenu: {
    changes: "Product updates",
    changesSince: "Product updates since",
    newProducts: "updated products",
    changedProperties: "updated properties",
    productsWithChangedProperties: "Products with updated properties",
    setToLastLogin: "Set to last login ({{lastLogin}})",
    invalidDateMessage: "Invalid Date",
    minDateMessage: "Date should not be before min date",
    maxDateMessage: "Date should not be after max date",
    amountNewFilters: "{{count}} new filters",
    amountNewProducts: "{{count}} new products",
    amountChangedProperties: "{{count}} updated properties",
    amountChangedProducts: "{{count}} products with updated properties",
    okLabel: "OK",
    cancelLabel: "Cancel",
  },
  filterCheckBox: {
    yes: "yes",
    no: "no",
  },
  searchForm: {
    label: "Search",
    tooltip: "Press Enter to search",
  },
  footer: {
    dataProtection: "Privacy Policy",
    termsAndConditions: "TAC",
    contactUs: "Contact us",
    imprint: "Imprint",
  },

  filter: {
    allItemsSameValue: "All products have value: {{value}}",
  },

  modal: {
    buttonName: "Features and functionality",
    close: "Close",
    heading1: "The digital market overview for wallboxes and charging stations",
    description1:
      "Get a quick overview of which charging systems are available on the market and match your project plans.",
    heading2: "What can I expect?",
    description2:
      "You will receive access to a clearly arranged web application which allows you to view the charging products available on the market with all their important features. The products can be searched and filtered, so you will quickly and clearly get the products that fit your project.",
    heading3: "How does the software work?",
    description3:
      "It's very simple: You open the software in your browser (no installation necessary), log in with your account and select the main category. You will then be shown a list of products available on the market. You can restrict these with a variety of filters which you can adapt to your needs. Detailed facts about each product will be presented in a clearly arranged tile display",
    heading4: "How do I get access?",
    description4:
      'Simply press the "Request access" button and send us a short e-mail. We will get in touch with you for further action.',
    searchResultScreenshot: "View of search results:",
  },
};
