import React from "react";
import PropTypes from "prop-types";

import { LOCALE_GERMAN } from "../../../assets/Translations/i18n";
import { ReactComponent as NewBadgeDe } from "../../../assets/icons/new_badge_de.svg";
import { ReactComponent as NewBadgeEn } from "../../../assets/icons/new_badge_en.svg";
import { ReactComponent as NewBadgeSmallEn } from "../../../assets/icons/new_badge_small_en.svg";
import { ReactComponent as NewBadgeSmallDe } from "../../../assets/icons/new_badge_small_de.svg";
import {useSelector} from "react-redux";

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: "",
};

export const NewBadge = (props) => {
  const { className } = props;
  const userLanguage = useSelector((state) => state.User.user.language);
  if (userLanguage === LOCALE_GERMAN) {
    return <NewBadgeDe className={className} />;
  }
  return <NewBadgeEn className={className} />;
};

NewBadge.propTypes = propTypes;
NewBadge.defaultProps = defaultProps;

export const NewBadgeSmall = (props) => {
  const { className } = props;
  const userLanguage = useSelector((state) => state.User.user.language);

  if (userLanguage === LOCALE_GERMAN) {
    return <NewBadgeSmallDe className={className} />;
  }
  return <NewBadgeSmallEn className={className} />;
};

NewBadgeSmall.propTypes = propTypes;
NewBadgeSmall.defaultProps = defaultProps;
