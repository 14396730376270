import _ from "lodash";
import { Api, isCancelError } from "../../Core/Api";
import { HTTP_POST, HTTP_PUT, HTTP_DELETE } from "../../Core/Constants";
import { createAsyncManager } from "../../utils/AsyncManager";

export const REQUEST_DATA = "REQUEST_DATA";
export const POST_DATA = "POST_DATA";
export const PUT_DATA = "PUT_DATA";
export const DELETE_DATA = "DELETE_DATA";
export const NETWORK_ERROR = "NETWORK_ERROR";
export const CANCEL_REQUEST = "CANCEL_REQUEST";
export const RECEIVE_DATA = "RECEIVE_DATA";
export const REQUEST_ERROR = "REQUEST_ERROR";

const requestData = (source) => ({
  type: REQUEST_DATA,
  source,
});

export const postRequest = (source, data) => ({
  type: POST_DATA,
  source,
  data,
});

const putRequest = (source, data) => ({
  type: PUT_DATA,
  source,
  data,
});

const deleteRequest = (source, data) => ({
  type: DELETE_DATA,
  source,
  data,
});

const networkError = (source, error) => ({
  type: NETWORK_ERROR,
  source,
  error,
  receivedAt: Date.now(),
});

const cancelRequest = (source) => ({
  type: CANCEL_REQUEST,
  source,
});

const receiveData = (source, data) => ({
  type: RECEIVE_DATA,
  source,
  data,
  receivedAt: Date.now(),
});

const requestError = (source, error, status) => ({
  type: REQUEST_ERROR,
  source,
  error,
  status,
  receivedAt: Date.now(),
});

const handleResponse = (source, request, receiver, dispatch, errorReceiver) =>
  request
    .then((response) => {
      dispatch(receiveData(source, response.data));
      return dispatch(receiver(response.data));
    })
    .catch((error) => {
      console.error(JSON.parse(JSON.stringify(error)));
      if (error.response) {
        if (
          error.response.headers &&
          error.response.headers["content-type"] === "application/json" &&
          _.isObject(error.response.data)
        ) {
          // reset api token
          // Api.setToken("");
          return dispatch(requestError(source, error.response.data, error.response.status));
        }
      }
      if (_.isObject(error.response) && error.response.status >= 400 && error.response.status < 500) {
        if (errorReceiver) {
          dispatch(cancelRequest(source));
          return dispatch(errorReceiver(source, error, error.response));
        }
        return dispatch(requestError(source, error, error.response.status));
      }
      if (isCancelError(error)) {
        return dispatch(cancelRequest(source));
      }
      return dispatch(networkError(source, error));
    });

const exportFunctions = {
  handleResponse,
};

export default exportFunctions;

export const fetchData = (source, receiver, errorReceiver) => async (dispatch) => {
  await dispatch(requestData(source));
  return exportFunctions.handleResponse(source, Api.request(source), receiver, dispatch, errorReceiver);
};

export const postData = (source, data, receiver, errorReceiver) => async (dispatch) => {
  await dispatch(postRequest(source, data));
  return exportFunctions.handleResponse(
    source,
    Api.request({ url: source, method: HTTP_POST, data }),
    receiver,
    dispatch,
    errorReceiver
  );
};

export const putData = (source, data, receiver, errorReceiver) => async (dispatch) => {
  await dispatch(putRequest(source, data));
  return exportFunctions.handleResponse(
    source,
    Api.request({ url: source, method: HTTP_PUT, data }),
    receiver,
    dispatch,
    errorReceiver
  );
};

export const deleteData = (source, data, receiver, errorReceiver) => async (dispatch) => {
  await dispatch(deleteRequest(source, data));
  return exportFunctions.handleResponse(
    source,
    Api.request({ url: source, method: HTTP_DELETE, data: data }),
    receiver,
    dispatch,
    errorReceiver
  );
};

const defaultRequestManager = createAsyncManager((...args) => Api.request(...args), {
  getKey: (sourceConfig) => {
    if (typeof sourceConfig === "string") {
      return sourceConfig;
    } else if (typeof sourceConfig === "object" && sourceConfig.url) {
      return sourceConfig.url;
    } else {
      throw new TypeError("The sourceConfig has to be a string or an object with a url property!");
    }
  },
});

export const asyncRequest = (source, receiver, options = {}, errorReceiver) => async (dispatch) => {
  const { requestManager = defaultRequestManager, useLocalErrorHandling = false } = options;
  const request = requestManager.create(source);
  try {
    const response = await request.call();
    dispatch(receiveData(source, response.data));
    return receiver ? dispatch(receiver(response.data, response)) : response;
  } catch (error) {
    if (request.isOutdated()) {
      return;
    }
    if (useLocalErrorHandling) {
      throw error;
    }
    if (errorReceiver) {
      dispatch(errorReceiver(source, error));
    }
    if (_.isObject(error.response)) {
      if (error.response.status === 404) {
        dispatch(requestError(source, error));
      }
      if (_.isObject(error.response.data)) {
        // request error (generated by the server)
        dispatch(receiveData(source, error));
        return receiver ? dispatch(receiver(error.response.data, error.response)) : error.response;
      }
      if (error.response.status >= 400 && error.response.status < 500) {
        dispatch(requestError(source, error));
      }
    }
    if (isCancelError(error)) {
      dispatch(cancelRequest(source));
    } else {
      dispatch(networkError(source, error));
    }
    throw error;
  }
};

export const postDataAsync = (source, data, receiver, errorReceiver, config = {}, options = {}) => (
  dispatch
) => {
  dispatch(postRequest(source, data));
  return dispatch(
    asyncRequest({ url: source, method: "post", data: data, ...config }, receiver, options, errorReceiver)
  );
};
