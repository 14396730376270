import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import reducers from "../Reducers";

const middleware = [thunk];

const Store = createStore(reducers, applyMiddleware(...middleware));

export default Store;
