import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, FormControl, InputLabel, Input, FormHelperText, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { updatePassword, resetPasswordErrors } from "../../../Reducers/User/UserActions";
import { showMessage } from "../../Components/Notifications/Notifications";

const useStyles = makeStyles({
  page: {
    flex: 1,
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 20px",
  },
  form: {
    flex: 1,
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    maxWidth: "22em",
    minWidth: "16em",
    marginBottom: "10vh",
  },
  input: {
    marginBottom: "3rem",
  },
});

export const ChangePwPage = () => {
  const classes = useStyles();

  const [form, setForm] = useState({
    currentPw: "",
    newPw: "",
    confirmPw: "",
    validNewPw: true,
    showError: false,
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const passwordErrors = useSelector((state) => state.User.passwordErrors);
  const passwordUpdateSuccessful = useSelector((state) => state.User.passwordUpdateSuccessful);
  const { wrongPassword } = passwordErrors;
  const { t } = useTranslation("changePassword");

  const isValidConfirmPw = form.newPw === form.confirmPw;

  const getIsDisabled = () =>
    !isValidConfirmPw ||
    !form.currentPw ||
    !form.newPw ||
    !form.confirmPw ||
    form.newPw.length < 8 ||
    form.confirmPw.length < 8 ||
    form.newPw !== form.confirmPw;

  const isDisabled = getIsDisabled();

  const resetErrors = () => {
    dispatch(resetPasswordErrors());
  };

  const navigateBack = () => {
    dispatch(resetPasswordErrors());
    history.goBack();
  };

  const handlePasswordChange = (event) => {
    const { value } = event.currentTarget;
    setForm({
      ...form,
      currentPw: value,
      showError: false,
    });
  };

  const handleNewPwChange = (event) => {
    const { value } = event.currentTarget;
    let validNewPw = true;
    let showError = false;
    if (value.length < 8) {
      validNewPw = false;
      showError = true;
    }
    setForm({
      ...form,
      newPw: value,
      showError,
      validNewPw,
    });
  };

  const handleConfirmPwChange = (event) => {
    const { value } = event.currentTarget;
    setForm({
      ...form,
      confirmPw: value,
      showError: false,
    });
  };

  const handleSubmit = (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (isDisabled) return;
    setLoading(true);
    dispatch(updatePassword(form.currentPw, form.newPw, form.confirmPw)).then(() => setLoading(false));
  };

  if (passwordUpdateSuccessful) {
    showMessage(t("passwordUpdateSuccessful"), 1500);
    navigateBack();
  }

  return (
    <div className={classes.page}>
      <h1>{t("changePassword")}</h1>
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormControl className={classes.input} error={wrongPassword} onClick={resetErrors}>
          <InputLabel htmlFor="ChangePwPage-currentPw">{t("currentPassword")}</InputLabel>
          <Input
            type="password"
            id="ChangePwPage-currentPw"
            value={form.currentPw}
            onChange={handlePasswordChange}
            aria-describedby="ChangePwPage-currentPw-text"
          />
          {wrongPassword && (
            <FormHelperText id="ChangePwPage-currentPw-text">{t("passwordNotCorrect")}</FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.input} error={form.showError && !form.validNewPw}>
          <InputLabel htmlFor="ChangePwPage-newPw">{t("newPassword")}</InputLabel>
          <Input
            type="password"
            id="ChangePwPage-newPw"
            value={form.newPw}
            onChange={handleNewPwChange}
            aria-describedby="ChangePwPage-newPw-text"
          />
          <FormHelperText id="ChangePwPage-newPw-text">{t("requirements")}</FormHelperText>
          {form.showError && !form.validNewPw && (
            <FormHelperText id="ChangePwPage-newPw-textInvalid">
              {t("requirementsNotMatching")}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.input} error={!isValidConfirmPw}>
          <InputLabel htmlFor="ChangePwPage-confirmPw">{t("confirmPassword")}</InputLabel>
          <Input
            type="password"
            id="ChangePwPage-confirmPw"
            value={form.confirmPw}
            onChange={handleConfirmPwChange}
            aria-describedby="ChangePwPage-confirmPw-text"
          />
          {!isValidConfirmPw && (
            <FormHelperText id="ChangePwPage-confirmPw-text">{t("passwordNotMatching")}</FormHelperText>
          )}
        </FormControl>

        {!loading && (
          <Button variant="contained" color="primary" type="submit" disabled={isDisabled}>
            {t("ok")}
          </Button>
        )}

        {loading && (
          <span>
            <CircularProgress />
          </span>
        )}
        <Button
          style={{
            color: "#00637c",
            fontSize: "1rem",
            textTransform: "none",
            marginTop: "5rem",
          }}
          component="button"
          color="primary"
          type="submit"
          onClick={navigateBack}
        >
          {t("back")}
        </Button>
      </form>
    </div>
  );
};

export default ChangePwPage;
