import _ from "lodash";

import {
  REQUEST_DATA,
  NETWORK_ERROR,
  POST_DATA,
  PUT_DATA,
  DELETE_DATA,
  RECEIVE_DATA,
  CANCEL_REQUEST,
  REQUEST_ERROR,
} from "./ApiActions";

const initialState = {
  sources: {},
  isFetching: false,
};

function getSourceState(action) {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        isFetching: true,
        source: action.source,
      };

    case POST_DATA:
      return {
        isFetching: true,
        isPosted: true,
        postData: action.data,
        source: action.source,
      };

    case PUT_DATA:
      return {
        isFetching: true,
        isPut: true,
        putData: action.data,
        source: action.source,
      };

    case DELETE_DATA:
      return {
        isFetching: true,
        isDelete: true,
        deleteData: action.data,
        source: action.source,
      };

    case NETWORK_ERROR:
      return {
        isFetching: false,
        error: action.error,
        source: action.source,
      };

    case REQUEST_ERROR:
      return {
        isFetching: false,
        error: action.error,
        source: action.source,
      };

    case RECEIVE_DATA:
      return {
        isFetching: false,
        data: action.data,
        source: action.source,
      };

    case CANCEL_REQUEST:
      return {
        isFetching: false,
        isCancelled: true,
        source: action.source,
      };
    default:
      console.error(`undefined case: ${action.type}`);
      return {};
  }
}

export default function apiReducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_DATA:
    case POST_DATA:
    case PUT_DATA:
    case DELETE_DATA:
    case NETWORK_ERROR:
    case RECEIVE_DATA:
    case CANCEL_REQUEST:
    case REQUEST_ERROR: {
      const sourceState = getSourceState(action);
      const sources = {
        ...state.sources,
        [action.source]: sourceState,
      };

      let hasAuthError = false;
      if (action.status === 401) {
        hasAuthError = true;
      }

      return {
        ...state,
        sources,
        hasAuthError,
        isFetching: _.reduce(sources, (result, value) => result || value.isFetching, false),
      };
    }

    default:
      return state;
  }
}
