import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Switch from "@material-ui/core/Switch";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Typography } from "@material-ui/core";
import "./Filter.css";
import { setFilter } from "../../../Reducers/Product/ProductActions";

const propTypes = {
  filter: PropTypes.objectOf(PropTypes.any),
};
const defaultProps = {
  filter: null,
};

const FilterSwitch = (props) => {
  const { filter } = props;

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const dispatch = useDispatch();

  const { t } = useTranslation("filter");

  let { value } = filter;
  // api sends strings
  if (value === "false") {
    value = false;
  } else if (value === "true") {
    value = true;
  }

  const handleChange = (event) => {
    // eslint-disable-next-line no-console
    console.log(`change toggle ${event.target.checked}`);
    // TODO debounce
    const _value = event.target.checked || false;
    filter.value = JSON.stringify(_value);
    dispatch(setFilter(filter));
    forceUpdate();
  };

  return (
    <React.Fragment>
      <FormControlLabel
        control={<Switch checked={value} onChange={handleChange} />}
        label={<Typography style={{ textTransform: "capitalize" }}>{t("yes")}</Typography>}
      />
    </React.Fragment>
  );
};

FilterSwitch.propTypes = propTypes;
FilterSwitch.defaultProps = defaultProps;

export default React.memo(FilterSwitch);
