import { createMuiTheme } from "@material-ui/core/styles";

// https://material-ui.com/customization/default-theme/

const fontScalar = 1.2; // Minor Third https://type-scale.com/

const customProps = {
  palette: {
    primary: {
      main: "#00637c",
    },
    secondary: {
      main: "#00637c",
    },
  },
  typography: {
    h1: {
      fontSize: `${fontScalar ** 6}rem`,
    },
    h2: {
      fontSize: `${fontScalar ** 5}rem`,
    },
    h3: {
      fontSize: `${fontScalar ** 4}rem`,
    },
    h4: {
      fontSize: `${fontScalar ** 3}rem`,
    },
    h5: {
      fontSize: `${fontScalar ** 2}rem`,
    },
    h6: {
      fontSize: `${fontScalar ** 1}rem`,
    },
  },
};

const customTheme = createMuiTheme(customProps);

customTheme.overrides = {
  MuiTypography: {
    subtitle1: {
      color: customTheme.palette.grey[600],
    },
  },
  MuiExpansionPanel: {
    root: {
      "&.Filter:after": {
        backgroundColor: customTheme.palette.primary.main,
      },
    },
  },
};

if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line no-console
  console.info("theme:", customTheme);
}

export { customProps, customTheme };
