import { combineReducers } from "redux";

import Api from "./Api/ApiReducer";
import Product from "./Product/ProductReducer";
import User from "./User/UserReducer";
import Filter from "./Filter/FilterReducer";
import Registration from "./Registration/RegistrationReducer";
import Manager from "./Manager/ManagerReducer";

export default combineReducers({
  Api,
  Product,
  User,
  Filter,
  Registration,
  Manager,
});
