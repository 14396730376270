import {
  RECEIVE_REGISTRATION,
  RECEIVE_REGISTRATION_ERROR,
  RESET_REGISTRATION_STATUS,
  LOAD_SERVICE_PACKAGES,
  RECEIVE_SERVICE_PACKAGES,
  LOAD_CATEGORIES,
  RECEIVE_CATEGORIES,
  RECEIVE_SERVICE_PACKAGES_FOR_UPGRADE,
} from "./RegistrationActions";

export const initialState = {
  registrationSuccessful: false,
  registrationError: false,
  categories: undefined,
  categoriesLoaded: false,
  fetchingCategories: false,
  servicePackages: undefined,
  servicePackagesLoaded: false,
  fetchingServicePackages: false,
};

function RegistrationReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_CATEGORIES: {
      return {
        ...state,
        categoriesLoaded: false,
        fetchingCategories: true,
      };
    }
    case RECEIVE_CATEGORIES: {
      return {
        ...state,
        categories: action.data,
        categoriesLoaded: true,
        fetchingCategories: false,
      };
    }
    case LOAD_SERVICE_PACKAGES: {
      return {
        ...state,
        servicePackagesLoaded: false,
        fetchingServicePackages: true,
      };
    }
    case RECEIVE_SERVICE_PACKAGES: {
      return {
        ...state,
        servicePackages: action.data,
        servicePackagesLoaded: true,
        fetchingServicePackages: false,
      };
    }
    case RECEIVE_SERVICE_PACKAGES_FOR_UPGRADE: {
      return {
        ...state,
        servicePackagesForUpgrade: action.data,
        servicePackagesLoaded: true,
        fetchingServicePackages: false,
      };
    }
    case RECEIVE_REGISTRATION: {
      return {
        ...state,
        registrationError: false,
        registrationSuccessful: true,
      };
    }
    case RECEIVE_REGISTRATION_ERROR: {
      let message =
        "Bei der Registrierung ist leider ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.";
      if (action.status === 422 && action.response && action.response.data) {
        switch (action.response.data) {
          case "EMAIL_EXISTS":
            message = "Diese E-Mail-Adresse wurde bereits regisriert.";
            break;
          case "ACCOUNT_EXISTS":
            message = "Diese Firma existiert bereits bei uns im System.";
            break;
          default:
            break;
        }
      }
      return {
        ...state,
        registrationError: true,
        registrationSuccessful: false,
        registrationErrorMessage: message,
      };
    }
    case RESET_REGISTRATION_STATUS: {
      return {
        ...state,
        registrationError: false,
        registrationSuccessful: false,
      };
    }
    default:
      return state;
  }
}

export default RegistrationReducer;
