import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    position: "fixed",
    right: 50,
    bottom: 100,
    width: 75,
    height: 75,
    borderRadius: "50%",
    backgroundColor: "#00637c",
    "&:hover": {
      backgroundColor: "#00586e",
    },
  },
  icon: {
    color: "white",
  },
});

export const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  const classes = useStyles();

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  };

  useEffect(() => {
    document.addEventListener("scroll", toggleVisibility);
    return () => document.removeEventListener("scroll", toggleVisibility);
  });

  return (
    visible && (
      <IconButton
        data-testid="scrollToTopButton"
        className={classes.button}
        component="button"
        color="primary"
        onClick={scrollToTop}
      >
        <ArrowUpwardIcon className={classes.icon} />
      </IconButton>
    )
  );
};

export default ScrollToTopButton;
