import React, { useEffect, forwardRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialTable from "material-table";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CreateIcon from "@material-ui/icons/Create";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import {
  deleteUser,
  updateUser,
  fetchAccountUsers,
  resetDeleteUserError,
  resetUpdateUserError,
  createUser,
  resetCreateUserError,
} from "../../../Reducers/Manager/ManagerActions";
import { showMessage } from "../Notifications/Notifications";
import { FormControlLabel, Switch, TextField, Typography } from "@material-ui/core";
import { LOCALE_ENGLISH, LOCALE_GERMAN } from "../../../assets/Translations/i18n";
import { isEmailValid } from "../../../utils/helpers";

export const UserList = () => {
  const { t } = useTranslation("userList");
  const { users, deleteUserError, updateUserError, createUserError, maxUsersReached } = useSelector(
    (state) => state.Manager
  );
  const me = useSelector((state) => state.User.user);
  const myId = get(me, "id");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [selectedUserIsManager, setSelectedUserIsManager] = useState(false);
  const [selectedUserIsActive, setSelectedUserIsActive] = useState(false);
  const [userData, setUserData] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [adding, setAdding] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [newUserIsManager, setNewUserIsManager] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState(null);
  const [newUserGivenName, setNewUserGivenName] = useState(null);
  const [newUserFamilyName, setNewUserFamilyName] = useState(null);
  const [newUserLanguage, setNewUserLanguage] = useState(LOCALE_GERMAN);
  const [addUserDialogOpen, setAddUserDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isNewEmailValid = isEmailValid(newUserEmail);
  const createUserDisabled = !isNewEmailValid || !newUserGivenName || !newUserFamilyName;

  const handleOpenDeleteDialog = (user) => {
    setUserData(user);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setUserData(null);
    setDeleteDialogOpen(false);
  };

  const handleDeleteUser = () => {
    const userId = get(userData, "id");
    setDeleting(true);
    dispatch(deleteUser(userId)).then(() => {
      setDeleting(false);
      setDeleteDialogOpen(false);
      dispatch(fetchAccountUsers());
    });
  };

  const handleOpenEditDialog = (user) => {
    setUserData(user);
    setSelectedUserIsManager(get(user, "isManager", false));
    setSelectedUserIsActive(get(user, "isActive", false));
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setUserData(null);
    setEditDialogOpen(false);
  };

  const resetNewUser = () => {
    setNewUserGivenName(null);
    setNewUserFamilyName(null);
    setNewUserEmail(null);
    setNewUserLanguage(LOCALE_GERMAN);
    setNewUserIsManager(false);
  };

  const handleOpenAddUserDialog = () => {
    setAddUserDialogOpen(true);
  };

  const handleCloseAddUserDialog = () => {
    resetNewUser();
    setAddUserDialogOpen(false);
  };

  const handleUpdateUser = () => {
    setEditing(true);
    dispatch(updateUser(userData)).then(() => {
      setEditing(false);
      setEditDialogOpen(false);
      dispatch(fetchAccountUsers());
    });
  };

  const handleUpdateIsManager = () => {
    const updatedUser = userData;
    updatedUser.isManager = !selectedUserIsManager;
    setSelectedUserIsManager(!selectedUserIsManager);
    setUserData(updatedUser);
  };

  const handleUpdateIsActive = () => {
    const updatedUser = userData;
    updatedUser.isActive = !selectedUserIsActive;
    setSelectedUserIsActive(!selectedUserIsActive);
    setUserData(updatedUser);
  };

  const handleAddUser = () => {
    setAdding(true);

    const newUser = {
      givenName: newUserGivenName,
      familyName: newUserFamilyName,
      email: newUserEmail,
      isManager: newUserIsManager,
      language: newUserLanguage,
    };
    dispatch(createUser(newUser)).then(() => {
      setAdding(false);
      setAddUserDialogOpen(false);
      resetNewUser();
      dispatch(fetchAccountUsers());
    });
  };

  const handleNewUserLanguageChange = (event) => {
    setNewUserLanguage(event.target.value);
  };

  const handleNewUserIsManager = () => {
    setNewUserIsManager(!newUserIsManager);
  };

  const handleNewUserGivenName = (event) => {
    setNewUserGivenName(event.target.value);
  };

  const handleNewUserFamilyName = (event) => {
    setNewUserFamilyName(event.target.value);
  };

  const handleNewUserEmail = (event) => {
    setNewUserEmail(event.target.value);
  };

  useEffect(() => {
    if (!users) {
      dispatch(fetchAccountUsers());
    } else {
      setLoading(false);
    }
    if (deleteUserError) {
      showMessage(t("errorDeletingUser"), 4000);
      dispatch(resetDeleteUserError());
    }
    if (updateUserError) {
      showMessage(t("errorUpdatingUser"), 4000);
      dispatch(resetUpdateUserError());
    }
    if (createUserError) {
      showMessage(maxUsersReached ? t("maxUsersReachedError") : t("errorCreatingUser"), 4000);
      dispatch(resetCreateUserError());
    }
  }, [dispatch, users, deleteUserError, updateUserError, createUserError, t, maxUsersReached]);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <div
      style={{
        margin: "0 30px 100px 30px",
      }}
    >
      {loading ? (
        <CircularProgress style={{ marginTop: 30 }} />
      ) : (
        <MaterialTable
          icons={tableIcons}
          columns={[
            { title: t("firstName"), field: "givenName" },
            { title: t("lastName"), field: "familyName" },
            { title: t("isManager"), field: "isManager", type: "boolean" },
            { title: t("isActive"), field: "isActive", type: "boolean" },
          ]}
          data={users.data}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              fontWeight: "bold",
            },
          }}
          title={t("tableHeader")}
          localization={{
            body: {
              emptyDataSourceMessage: t("noEntries"),
            },
            pagination: {
              labelDisplayedRows: t("labelDisplayedRows"),
              labelRowsSelect: t("labelRowsSelect"),
              labelRowsPerPage: t("labelRowsPerPage"),
              firstAriaLabel: t("firstPage"),
              firstTooltip: t("firstPage"),
              previousAriaLabel: t("previousPage"),
              previousTooltip: t("previousPage"),
              nextAriaLabel: t("nextPage"),
              nextTooltip: t("nextPage"),
              lastAriaLabel: t("lastPage"),
              lastTooltip: t("lastPage"),
            },
            toolbar: {
              searchTooltip: t("search"),
              searchPlaceholder: t("search"),
            },
          }}
          actions={[
            (rowData) => ({
              icon: CreateIcon,
              tooltip: t("editUser"),
              onClick: (event) => handleOpenEditDialog(rowData),
              disabled: rowData.id === myId,
            }),
            (rowData) => ({
              icon: DeleteOutlineIcon,
              tooltip: t("deleteUser"),
              onClick: (event) => handleOpenDeleteDialog(rowData),
              disabled: rowData.id === myId,
            }),
            {
              icon: AddBoxIcon,
              tooltip: t("addUser"),
              isFreeAction: true,
              onClick: (event) => handleOpenAddUserDialog(),
            },
          ]}
        />
      )}
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={deleteDialogOpen}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{t("deleteUser")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("deleteUserDescription", {
                givenName: get(userData, "givenName"),
                familyName: get(userData, "familyName"),
                email: get(userData, "email"),
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {!deleting && (
              <>
                <Button autoFocus onClick={handleCloseDeleteDialog} color="default">
                  {t("cancel")}
                </Button>
                <Button onClick={handleDeleteUser} color="primary" autoFocus>
                  {t("delete")}
                </Button>
              </>
            )}
            {deleting && <CircularProgress size="2rem" style={{ marginRight: "1rem" }} />}
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={editDialogOpen}
          onClose={handleCloseEditDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{t("editUser")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography>{`${t("name")}  ${get(userData, "givenName", "")} ${get(
                userData,
                "familyName",
                ""
              )}`}</Typography>
              <Typography>{`${t("email")}  ${get(userData, "email", "")}`}</Typography>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <FormControlLabel
                  control={<Switch checked={selectedUserIsManager} onChange={handleUpdateIsManager} />}
                  label={<Typography>{t("isManager")}</Typography>}
                />
                <FormControlLabel
                  control={<Switch checked={selectedUserIsActive} onChange={handleUpdateIsActive} />}
                  label={<Typography>{t("isActive")}</Typography>}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {!editing && (
              <>
                <Button autoFocus onClick={handleCloseEditDialog} color="default">
                  {t("cancel")}
                </Button>
                <Button onClick={handleUpdateUser} color="primary" autoFocus>
                  {t("updateUser")}
                </Button>
              </>
            )}
            {editing && <CircularProgress size="2rem" style={{ marginRight: "1rem" }} />}
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={addUserDialogOpen}
          onClose={handleCloseEditDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{t("addUser")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  error={newUserGivenName === ""}
                  label={t("firstName")}
                  style={{ marginBottom: "1rem" }}
                  id="outlined-basic"
                  variant="outlined"
                  required
                  onChange={(event) => handleNewUserGivenName(event)}
                />
                <TextField
                  error={newUserFamilyName === ""}
                  label={t("lastName")}
                  style={{ marginBottom: "1rem" }}
                  id="outlined-basic"
                  variant="outlined"
                  required
                  onChange={(event) => handleNewUserFamilyName(event)}
                />
                <TextField
                  error={!isNewEmailValid && newUserEmail !== null}
                  label={t("newUserEmail")}
                  style={{ marginBottom: "1rem" }}
                  id="outlined-basic"
                  variant="outlined"
                  required
                  onChange={(event) => handleNewUserEmail(event)}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <FormControl style={{ marginBottom: "1rem" }}>
                  <InputLabel id="select-label">{t("language")}</InputLabel>
                  <Select
                    labelId="select-label"
                    id="select"
                    value={newUserLanguage}
                    onChange={(event) => handleNewUserLanguageChange(event)}
                  >
                    <MenuItem value={LOCALE_GERMAN}>{t("german")}</MenuItem>
                    <MenuItem value={LOCALE_ENGLISH}>{t("english")}</MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel
                  style={{ marginBottom: "1rem" }}
                  control={<Switch checked={newUserIsManager} onChange={handleNewUserIsManager} />}
                  label={<Typography>{t("isManager")}</Typography>}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {!adding && (
              <>
                <Button autoFocus onClick={handleCloseAddUserDialog} color="default">
                  {t("cancel")}
                </Button>
                <Button onClick={handleAddUser} color="primary" autoFocus disabled={createUserDisabled}>
                  {t("addUser")}
                </Button>
              </>
            )}
            {adding && <CircularProgress size="2rem" style={{ marginRight: "1rem" }} />}
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default UserList;
