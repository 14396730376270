import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CookiesProvider, useCookies } from "react-cookie";
import { Provider, useDispatch } from "react-redux";
import { customTheme } from "./theme/custom-theme";
import "./main.css";
import Store from "../Core/Store";
// eslint-disable-next-line import/no-named-as-default
import Api from "../Core/Api";
import { setUser, deleteToken } from "../Reducers/User/UserActions";

const propTypes = {
  children: PropTypes.node,
};
const defaultProps = {
  children: null,
};

export const StatefullCookieContext = ({ children }) => {
  const [cookies, removeCookie] = useCookies(["authToken", "user"]);
  const dispatch = useDispatch();

  const cookieAuthToken = cookies.authToken;
  const cookieUser = cookies.user;

  if (cookieAuthToken && cookieUser) {
    Api.setToken(cookieAuthToken);
    dispatch(setUser(cookieUser));
  }

  const syncLogout = (event) => {
    if (event.key === "logout") {
      removeCookie("user");
      removeCookie("authToken");
      dispatch(deleteToken());
    }
  };

  useEffect(() => {
    window.addEventListener("storage", syncLogout);
    return () => window.removeEventListener("storage", syncLogout);
  });

  return children;
};

export const RootContext = ({ children }) => (
  <div className="RootContext">
    <Provider store={Store}>
      <CssBaseline />
      <Router>
        <CookiesProvider>
          <MuiThemeProvider theme={customTheme}>
            <StatefullCookieContext>{children}</StatefullCookieContext>
          </MuiThemeProvider>
        </CookiesProvider>
      </Router>
    </Provider>
  </div>
);
RootContext.propTypes = propTypes;
RootContext.defaultProps = defaultProps;
export default RootContext;
