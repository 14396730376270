import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardActions, Collapse, List, Typography, CardHeader } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useInView } from "react-intersection-observer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import ReactTooltip from "react-tooltip";
import "./Product.css";
import { LOCALE_GERMAN } from "../../../assets/Translations/i18n";
import { NewBadge } from "../NewBadge/NewBadge";
import Button from "@material-ui/core/Button";
import RequestProductModal from "../RequestProductModal/RequestProductModal";
import ProductDetailsDialog from "../ProductDetailsDialog/ProductDetailsDialog";
import PropertyGroup from "./PropertyGroup/PropertyGroup";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  productNewBadge: {
    marginRight: -16,
  },
  subHeaderNew: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

export const ProductPropsShape = {
  identifier: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  primary: PropTypes.bool,
};

export const productGroupShape = {
  identifier: PropTypes.string,
  groupName: PropTypes.string,
  properties: PropTypes.arrayOf(PropTypes.shape(ProductPropsShape)),
};

export const containerShape = {
  current: PropTypes.object,
};

const propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  image: PropTypes.node,
  primaryGroups: PropTypes.arrayOf(PropTypes.shape(productGroupShape)),
  secondaryGroups: PropTypes.arrayOf(PropTypes.shape(productGroupShape)),
  onSelectRow: PropTypes.func,
  selectedRow: PropTypes.string,
  onExpandView: PropTypes.func,
  expandedView: PropTypes.bool,
  productIdentifier: PropTypes.string,
  userLanguage: PropTypes.string,
  container: PropTypes.objectOf(PropTypes.shape(containerShape)),
  isNew: PropTypes.bool,
  productId: PropTypes.number,
  collapseProductsBy: PropTypes.func,
  isCollapsed: PropTypes.bool,
  manufacturerGroupSize: PropTypes.number,
  countryIcon: PropTypes.string,
};

const defaultProps = {
  title: "–",
  subtitle: "–",
  image: null,
  primaryGroups: [],
  secondaryGroups: [],
  onSelectRow: undefined,
  selectedRow: undefined,
  onExpandView: undefined,
  expandedView: undefined,
  productIdentifier: "",
  userLanguage: LOCALE_GERMAN,
  container: {},
  isNew: false,
  countryIcon: null,
};

const Product = (props) => {
  const {
    container,
    title,
    subtitle,
    image,
    primaryGroups,
    secondaryGroups,
    expandedView,
    onExpandView,
    productIdentifier,
    userLanguage,
    isNew,
    productId,
    collapseProductsBy,
    isCollapsed,
    manufacturerGroupSize,
    countryIcon,
  } = props;

  const { t } = useTranslation("product");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isProductDetailsModalOpen, setProductDetailsModalOpen] = useState(false);
  const [displayProductDetailsButton, setDisplayProductDetailsButton] = useState(false);

  const collapseProductTooltip = isCollapsed
    ? t("extendProducts", { manufacturer: subtitle, count: manufacturerGroupSize })
    : t("collapseProducts", { manufacturer: subtitle });

  const handleSelectItem = (property) => {
    if (props.onSelectRow) {
      props.onSelectRow(property.identifier);
    }
  };

  const [ref, inView] = useInView({
    root: container.current,
    triggerOnce: true,
    rootMargin: "10000px 1000px",
  });

  const classes = useStyles();

  const countryIconWrapper = (
    <div className="Product-country-icon">{countryIcon && <img src={countryIcon} alt="" />}</div>
  );

  const subHeader = (
    <div>
      <Typography>{subtitle}</Typography>
      {countryIconWrapper}
    </div>
  );

  const subHeaderNew = (
    <div className={classes.subHeaderNew}>
      <Typography>{subtitle}</Typography>
      <NewBadge style={classes.productNewBadge} language={userLanguage} />
      {countryIconWrapper}
    </div>
  );

  const renderCardHeader = () => (
    <>
      <ProductDetailsDialog
        handleClose={() => setProductDetailsModalOpen(false)}
        open={isProductDetailsModalOpen}
        image={image}
        title={title}
        subtitle={subtitle}
        primaryGroups={primaryGroups}
        secondaryGroups={secondaryGroups}
        userLanguage={userLanguage}
        handleSelectItem={handleSelectItem}
        selectedRow={props.selectedRow}
      />

      <CardHeader style={{ textAlign: "left" }} title={title} subheader={isNew ? subHeaderNew : subHeader} />
    </>
  );

  const renderImage = (noInteraction) => (
    <>
      {image && inView && (
        <div
          onMouseOver={() => noInteraction === false && setDisplayProductDetailsButton(true)}
          onMouseLeave={() => noInteraction === false && setDisplayProductDetailsButton(false)}
          onClick={() => noInteraction === false && setProductDetailsModalOpen(true)}
          color="default"
          className="bg-image Product-image"
          data-tip={productIdentifier}
          data-for="product_identifier"
          style={{ backgroundImage: `url(${image})` }}
        >
          {displayProductDetailsButton && (
            <div className="bg-image Product-displayProductDetailsButtonWrapper">
              <Button
                component="button"
                className="Product-displayProductDetailsButton"
                style={{
                  color: "rgba(0, 0, 0, 0.87)",
                  backgroundColor: "#E0E0E0",
                }}
                variant="contained"
                color="primary"
                onClick={() => setProductDetailsModalOpen(true)}
              >
                {t("displayProductInformation")}
              </Button>
            </div>
          )}

          {noInteraction === false && (
            <Tooltip title={collapseProductTooltip}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  collapseProductsBy(subtitle);
                }}
                variant="contained"
                color="primary"
                className="Product-collapse-button"
              >
                {isCollapsed && <LastPageIcon />}
                {!isCollapsed && <FirstPageIcon />}
              </Button>
            </Tooltip>
          )}
        </div>
      )}
    </>
  );

  return (
    <Card className="Product" ref={ref} raised={isCollapsed}>
      {renderCardHeader()}
      {renderImage(false)}

      {image && inView && productIdentifier && (
        <ReactTooltip
          id="product_identifier"
          getContent={(dataTip) => (
            <div>
              <h3>{dataTip}</h3>
            </div>
          )}
          effect="solid"
          clickable
          delayShow={2000}
          offset={{ top: -30 }}
          place="top"
          border
          type="light"
        />
      )}

      <Button
        component="button"
        style={{
          color: "rgba(0, 0, 0, 0.87)",
          backgroundColor: "#E0E0E0",
        }}
        variant="contained"
        color="primary"
        onClick={() => setModalOpen(true)}
      >
        {t("requestProductButton")}
      </Button>

      <RequestProductModal
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        productId={productId}
        productSummary={
          <>
            {renderCardHeader()}
            {renderImage(true)}
          </>
        }
      />

      <>
        <CardContent className="Product-content">
          <div className={expandedView && inView ? "Product-box-shadow" : ""}>
            <List className={expandedView ? "Product-properties-expanded" : "Product-properties"}>
              {primaryGroups.map((propertyGroup) => (
                <PropertyGroup
                  propertyGroup={propertyGroup}
                  userLanguage={userLanguage}
                  handleSelectItem={handleSelectItem}
                  selectedRow={props.selectedRow}
                />
              ))}
            </List>
            <Collapse
              in={expandedView && inView}
              style={{ display: "flex", flexDirection: "column" }}
              timeout="auto"
              unmountOnExit
            >
              {secondaryGroups.map((propertyGroup) => (
                <PropertyGroup
                  propertyGroup={propertyGroup}
                  userLanguage={userLanguage}
                  handleSelectItem={handleSelectItem}
                  selectedRow={props.selectedRow}
                />
              ))}
            </Collapse>
          </div>
        </CardContent>
        <CardActions className="Product-actions">
          <Typography className="Expand-icon-label">
            {expandedView ? t("showPrimaryOnly") : t("showAllProperties")}
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expandedView,
            })}
            onClick={onExpandView}
            aria-expanded={expandedView}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
      </>
    </Card>
  );
};
Product.propTypes = propTypes;
Product.defaultProps = defaultProps;

const areEqual = (props, nextProps) => props === nextProps;
Product.whyDidYourRender = true;
export default React.memo(Product, areEqual);
