const rmAsterisksRegex = /^(\*\s*)/;
const removeLeadingAsterisks = (value) => value.replace(rmAsterisksRegex, "");

export default removeLeadingAsterisks;

export const isEmailValid = (email) => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
};

const getDateTime = (date) => {
  const dt = new Date(date);
  let currentDate = dt.getDate();
  let currentMonth = dt.getMonth() + 1;
  let currentYear = dt.getFullYear();
  let currentHours = dt.getHours();
  let currentMins = dt.getMinutes();
  let currentSecs = dt.getSeconds();
  let currentDatetime;

  // Add 0 before date, month, hrs, min or secs if they are less than 10
  currentDate = currentDate < 10 ? "0" + currentDate : currentDate;
  currentMonth = currentMonth < 10 ? "0" + currentMonth : currentMonth;
  currentHours = currentHours < 10 ? "0" + currentHours : currentHours;
  currentMins = currentMins < 10 ? "0" + currentMins : currentMins;
  currentSecs = currentSecs < 10 ? "0" + currentSecs : currentSecs;

  // Current datetime
  // String such as 2016-07-16T19:20:30
  currentDatetime =
    currentYear +
    "-" +
    currentMonth +
    "-" +
    currentDate +
    "T" +
    currentHours +
    ":" +
    currentMins +
    ":" +
    currentSecs;

  return currentDatetime;
};

const getTimezoneStandard = (date) => {
  const dt = new Date(date);
  let timezoneOffsetMin = dt.getTimezoneOffset();
  let offsetHours = parseInt(Math.abs(timezoneOffsetMin / 60));
  let offsetMin = Math.abs(timezoneOffsetMin % 60);
  let timezoneStandard;

  if (offsetHours < 10) offsetHours = "0" + offsetHours;

  if (offsetMin < 10) offsetMin = "0" + offsetMin;

  // Add an opposite sign to the offset
  // If offset is 0, it means timezone is UTC
  if (timezoneOffsetMin < 0) timezoneStandard = "+" + offsetHours + ":" + offsetMin;
  else if (timezoneOffsetMin > 0) timezoneStandard = "-" + offsetHours + ":" + offsetMin;
  else if (timezoneOffsetMin === 0) timezoneStandard = "Z";

  // Timezone difference in hours and minutes
  // String such as +5:30 or -6:00 or Z
  return timezoneStandard;
};

export const formatDate = (date) => {
  const currentDateTime = getDateTime(date);
  const timeZoneStandard = getTimezoneStandard(date);
  return `${currentDateTime}${timeZoneStandard}`;
};
