import React from "react";
import { Route, Switch } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "../assets/Translations/i18n";
import { version } from "../../package.json";
import { RootContext } from "./RootContext";
import "./App.css";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { RegistrationPage } from "./pages/RegistrationPage/RegistrationPage";
import { DataProtectionPage } from "./pages/DataProtectionPage/DataProtectionPage";
import { ImprintPage } from "./pages/ImprintPage/ImprintPage";
import { TermsPage } from "./pages/TermsPage/TermsPage";
import { CategorySelectionPage } from "./pages/CategorySelectionPage/CategorySelectionPage";
import { OverviewPage } from "./pages/OverviewPage/OverviewPage";
import { ChangePwPage } from "./pages/ChangePwPage/ChangePwPage";
import { ManageAccountPage } from "./pages/ManageAccountPage/ManageAccountPage";
import { Notifications } from "./Components/Notifications/Notifications";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import LoginRoute from "./Components/LoginRoute/LoginRoute";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import Numeral from "numeral";

export const LOGIN = "login";
export const REGISTRATION = "registration";
export const CATEGORY_SELECTION = "category-selection";
export const OVERVIEW = "overview";
export const CHANGE_PASSWORD = "change-password";
export const MANAGE_ACCOUNT = "manage-account";
export const DATA_PROTECTION = "data-protection";
export const IMPRINT = "imprint";
export const TERMS = "terms";

// load a locale
Numeral.register("locale", "de", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  currency: {
    symbol: "€",
  },
});
Numeral.locale("de");

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <RootContext>
        <div className="App">
          <div className="app-info" style={{ display: "none" }} data-version={version} />
          <Header />
          <React.Fragment>
            <Switch>
              <LoginRoute exact path="/" component={RegistrationPage} />
              <LoginRoute exact path={`/${LOGIN}`} component={LoginPage} />
              <LoginRoute exact path={`/${REGISTRATION}`} component={RegistrationPage} />
              <PrivateRoute exact path={`/${CATEGORY_SELECTION}`} component={CategorySelectionPage} />
              <PrivateRoute exact path={`/${OVERVIEW}`} component={OverviewPage} />
              <PrivateRoute exact path={`/${CHANGE_PASSWORD}`} component={ChangePwPage} />
              <PrivateRoute exact path={`/${MANAGE_ACCOUNT}`} component={ManageAccountPage} />
              <Route exact path={`/${DATA_PROTECTION}`} component={DataProtectionPage} />
              <Route exact path={`/${IMPRINT}`} component={ImprintPage} />
              <Route exact path={`/${TERMS}`} component={TermsPage} />
            </Switch>
          </React.Fragment>
          <Footer />
          <Notifications />
        </div>
      </RootContext>
    </I18nextProvider>
  );
}

export default App;
