import React from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropertyGroup from "../Product/PropertyGroup/PropertyGroup";

const useStyles = makeStyles({
  closeButton: {
    position: "absolute",
    right: 4,
    top: 4,
    color: "grey",
  },
  root: {
    padding: 16,
    display: "flex",
  },

  imageContainer: {
    width: "500px",
    display: "block"
  },

  image: {
    maxWidth: "100%"
  },

  left: {
    textAlign: "center",
    paddingTop: "30px"
  },

  right: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    height: "90vh",
    width: "100%",
    overflowX: "auto",
    paddingTop: "30px",
    columnGap: "20px",

    "& .MuiListItem-root": {
      maxWidth: "40em",
    },

    "& .title-container": {
      paddingTop: "7px"
    },
  },
  propertyColumn: {
    flex: "1 0 25%",
  },
});

const ProductDetailsDialog = ({
  open,
  handleClose,
  image,
  title,
  subtitle,
  primaryGroups,
  secondaryGroups,
  userLanguage,
  handleSelectItem,
  selectedRow,
}) => {
  const classes = useStyles();
  const categoryGroup = primaryGroups && primaryGroups.find((g) => g.groupName === null);
  const categoryProp = categoryGroup && categoryGroup.properties.find((p) => p.name === "Kategorie");

  // for the detail view we fo not show manufacturer and category again because it is already as a subtitle
  const detailPrimaryGroups = primaryGroups && primaryGroups.slice(1);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"xl"}>
      <IconButton className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <div className={classes.root}>

        <div className={classes.left}>
          <div className={classes.imageContainer}>
            <img src={image} alt="product" className={classes.image} />
          </div>

          <Typography variant="h5">{title}</Typography>
          <Typography>{subtitle}</Typography>
          {categoryProp && categoryProp.value && <Typography>{categoryProp.value}</Typography>}
        </div>

        <div className={classes.right}>
          {[...detailPrimaryGroups, ...secondaryGroups].map((propertyGroup) => (
            <>
              <PropertyGroup
                propertyGroup={propertyGroup}
                userLanguage={userLanguage}
                handleSelectItem={handleSelectItem}
                selectedRow={selectedRow}
              />
            </>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export default ProductDetailsDialog;
