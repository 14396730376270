import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import _ from "lodash";
import { browserName, deviceType, fullBrowserVersion, osName, osVersion } from "react-device-detect";
import { Button, TextField, FormControl, InputLabel, Input, FormHelperText } from "@material-ui/core";
import { login, setLanguage } from "../../../Reducers/User/UserActions";
import { resetProduct } from "../../../Reducers/Product/ProductActions";
import { NETWORK_ERROR_INVALID_REQUEST } from "../../../Core/Constants";
import {
  showLoadingMessage,
  showMessage,
  deleteMessageById,
} from "../../Components/Notifications/Notifications";
import logo from "../../../assets/logo.png";
import { LOCALE_ENGLISH, LOCALE_GERMAN } from "../../../assets/Translations/i18n";
import { CATEGORY_SELECTION } from "../../App";
import qs from "qs";

const useStyles = makeStyles({
  page: {
    flex: 1,
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    height: "7rem",
    marginBottom: "1rem",
  },
  headline: {
    marginBottom: "2rem",
    fontFamily: "Ubuntu, sans-serif",
  },
  form: {
    display: "flex",
    flexFlow: "column",
    maxWidth: "22em",
    marginBottom: "10vh",
  },
  input: {
    marginBottom: "2rem",
  },
});

export const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory();
  let location = useLocation();

  const [form, setForm] = useState({
    name: "",
    password: "",
    valid: true,
    showError: false,
    error: "",
  });
  const [loading, setLoading] = useState(false);

  const [cookies, setCookie] = useCookies(["authToken", "user"]);

  const dispatch = useDispatch();

  const { t } = useTranslation("login");

  const handleNameChange = (event) => {
    const { value } = event.currentTarget;
    setForm({
      ...form,
      name: value,
      showError: false,
    });
  };

  const handlePasswordChange = (event) => {
    const { value } = event.currentTarget;
    setForm({
      ...form,
      password: value,
      showError: false,
    });
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    let isValid = true;
    let error = "";
    const _form = form;
    const deviceInfo = {
      operatingSystem: `${osName} ${osVersion}`,
      browserName: `${browserName} ${fullBrowserVersion}`,
      deviceType,
    };

    setLoading(true);
    const message = showLoadingMessage();

    const result = await dispatch(login(form.name, form.password, deviceInfo)).catch((_error) => {
      console.error(_error);
      return false;
    });

    if (result.data) {
      // reset the productstate in case admin has restricted/extended category access for the user
      dispatch(resetProduct());
      setCookie("authToken", result.data.access_token);
      setCookie("user", result.data.currentUser);
      const fallbackLanguage = cookies.i18next || LOCALE_GERMAN;
      const language = _.get(result.data.currentUser, "language", fallbackLanguage);

      const languageSetViaUrl = qs.parse(location.search, { ignoreQueryPrefix: true }).lng;

      if (
        languageSetViaUrl &&
        languageSetViaUrl !== language &&
        (languageSetViaUrl === LOCALE_GERMAN || languageSetViaUrl === LOCALE_ENGLISH)
      ) {
        dispatch(setLanguage(languageSetViaUrl));
        i18n.changeLanguage(languageSetViaUrl ? languageSetViaUrl : language);
      }
      return history.push(`/${CATEGORY_SELECTION}`);
    }

    if (result.error) {
      isValid = false;
      error =
        _.get(result.error, "error", "") === NETWORK_ERROR_INVALID_REQUEST
          ? t("missingParameters")
          : t("invalidCombination");
      showMessage(error);
    }

    _form.valid = isValid;
    _form.error = error;
    _form.showError = true;
    setForm({ ..._form });
    setLoading(false);
    return deleteMessageById(message.id);
  };

  return (
    <div className={classes.page}>
      <img className={classes.logo} src={logo} alt="logo" />

      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          className={classes.input}
          id="LoginPage-username"
          autoComplete="current-username"
          type="text"
          error={form.showError && !form.valid}
          label={t("email")}
          onChange={handleNameChange}
          value={form.name}
        />

        <FormControl className={classes.input} error={form.showError && !form.valid}>
          <InputLabel htmlFor="LoginPage-password">{t("password")}</InputLabel>
          <Input
            type="password"
            id="LoginPage-password"
            value={form.password}
            onChange={handlePasswordChange}
            aria-describedby="LoginPage-password-text"
          />
          {form.error && <FormHelperText id="LoginPage-error-text">{form.error}</FormHelperText>}
        </FormControl>

        <Button variant="contained" color="primary" type="submit">
          {t("login")}
          {loading && <span>…</span>}
        </Button>
      </form>
    </div>
  );
};

export default LoginPage;
