import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./RequestProductModal.css";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { requestProduct, resetRequestProduct } from "../../../Reducers/Product/ProductActions";
import LinearProgress from "@material-ui/core/LinearProgress";

const RequestProductModal = ({ isOpen, onClose, productSummary, productId }) => {
  const { t } = useTranslation("product");
  const dispatch = useDispatch();
  const [requestServiceMessage, setRequestServiceMessage] = useState("");
  const [requestProductMessage, setRequestProductMessage] = useState("");
  const { sendingRequestProductRequest, requestProductSuccess } = useSelector((state) => state.Product);

  useEffect(() => {
    if (!sendingRequestProductRequest && requestProductSuccess === true) {
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [sendingRequestProductRequest, requestProductSuccess]);

  const handleClose = () => {
    setRequestProductMessage("");
    setRequestServiceMessage("");
    onClose();
    dispatch(resetRequestProduct());
  };

  return (
    <Dialog onClose={handleClose} open={isOpen} maxWidth={"md"} fullWidth={true}>
      {sendingRequestProductRequest && <LinearProgress />}
      {!sendingRequestProductRequest && requestProductSuccess === true && (
        <div className="request-modal-success">{t("requestProductSuccess")}</div>
      )}
      {!sendingRequestProductRequest && requestProductSuccess === false && (
        <div className="request-modal-error">{t("requestProductError")}</div>
      )}
      <div className="request-modal-container">
        <div className="request-modal-product">
          {productSummary}
        </div>
        <div className="request-modal-inputs">
          <TextField
            label={t("requestProduct")}
            placeholder={t("requestProductNotice")}
            defaultValue=" "
            multiline
            rows={4}
            variant="outlined"
            value={requestProductMessage}
            onChange={(event) => setRequestProductMessage(event.target.value)}
          />
          <TextField
            label={t("requestService")}
            placeholder={t("requestServiceNotice")}
            defaultValue=" "
            multiline
            rows={4}
            variant="outlined"
            value={requestServiceMessage}
            onChange={(event) => setRequestServiceMessage(event.target.value)}
          />
        </div>
        <Button
          component="button"
          style={{
            color: "#ffffff",
            fontSize: ".8rem",
            marginBottom: 4,
            paddingTop: 8,
            paddingBottom: 8,
            fontFamily: "Ubuntu, sans-serif",
            marginTop: 16,
            width: "100%",
          }}
          variant="contained"
          color="primary"
          disabled={!requestServiceMessage && !requestProductMessage}
          onClick={() => {
            dispatch(
              requestProduct({
                productId,
                productRequest: requestProductMessage,
                serviceRequest: requestServiceMessage,
              })
            );
          }}
        >
          {t("requestProductButton")}
        </Button>
      </div>
    </Dialog>
  );
};

export default RequestProductModal;
