/* eslint-disable no-use-before-define */
import _ from "lodash";
import { fetchData, postData, postDataAsync } from "../Api/ApiActions";
import { resetFilterPanelExpanded } from "../Filter/FilterActions";

export const LOAD_PRODUCTS = "PRODUCT_LOAD_PRODUCTS";
export const RECEIVE_PRODUCTS = "PRODUCT_RECEIVE_PRODUCTS";
export const LOAD_FILTERS = "PRODUCT_LOAD_FILTERS";
export const LOAD_CATEGORIES = "PRODUCT_LOAD_CATEGORIES";
export const RECEIVE_FILTERS = "PRODUCT_RECEIVE_FILTERS";
export const CHANGE_FILTERS = "PRODUCT_CHANGE_FILTERS";
export const SET_FILTER = "PRODUCT_SET_FILTER";
export const RECEIVE_CATEGORIES = "PRODUCT_RECEIVE_CATEGORIES";
export const SET_ACTIVE_CATEGORY = "PRODUCT_SET_ACTIVE_CATEGORY";
export const RESET = "PRODUCT_RESET";
export const RESET_FILTERS = "PRODUCT_RESET_FILTERS";
export const RESET_ACTIVE_FILTERS = "PRODUCT_RESET_ACTIVE_FILTERS";
export const SET_SEARCH_PARAM = "PRODUCT_SET_SEARCH_PARAM";
export const RECEIVE_PRODUCT_CHANGES = "PRODUCT_RECEIVE_PRODUCT_CHANGES";
export const SET_USER_SELECTED_REFERENCE_DATE = "PRODUCT_SET_USER_SELECTED_REFERENCE_DATE";
export const RESET_PRODUCT_CHANGES = "PRODUCT_RESET_PRODUCT_CHANGES";
export const SEND_PRODUCT_REQUEST = "SEND_PRODUCT_REQUEST";
export const RECEIVE_REQUEST_PRODUCT_RESPONSE = "RECEIVE_REQUEST_PRODUCT_RESPONSE";
export const RECEIVE_REQUEST_PRODUCT_ERROR = "RECEIVE_REQUEST_PRODUCT_ERROR";
export const RESET_REQUEST_PRODUCT = "RESET_REQUEST_PRODUCT";

export const ROUTE_PRODUCTS = "api/products";
export const ROUTE_CATEGORIES = "api/me/categories";
export const ROUTE_PRODUCTS_SUMMARIZED_CHANGES = "api/products/summarized-changes";

export const createRouteForFetchFilters = (categoryId, subCategoryId) =>
  `/api/filters?categoryId=${categoryId}&subCategoryId=${subCategoryId}`;
export const createRouteForFetchFiltersWithReferenceDate = (categoryId, subCategoryId, referenceDate) =>
  `/api/filters?categoryId=${categoryId}&subCategoryId=${subCategoryId}&referenceDate=${referenceDate}`;

const loadCategories = () => ({
  type: LOAD_CATEGORIES,
});

export const fetchCategories = () => (dispatch) => {
  dispatch(exportFunctions.loadCategories());
  dispatch(fetchData(ROUTE_CATEGORIES, receiveCategories));
};

export const receiveCategories = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_CATEGORIES,
    data,
  });
};

const loadFilters = () => ({
  type: LOAD_FILTERS,
});

const sendRequestProductRequest = () => ({
  type: SEND_PRODUCT_REQUEST,
});

const receiveRequestProductResponse = () => ({
  type: RECEIVE_REQUEST_PRODUCT_RESPONSE,
});

const receiveRequestProductError = () => ({
  type: RECEIVE_REQUEST_PRODUCT_ERROR,
});

export const requestProduct = (data) => (dispatch, getState) => {
  const { activeCategory } = getState().Product;
  const { categoryId, subCategoryId } = activeCategory;
  const { productId, ...requestBodyParams } = data;

  const requestBody = {
    ...requestBodyParams,
    categoryId,
    subCategoryId,
  };

  dispatch(sendRequestProductRequest());

  return dispatch(
    postData(
      `/api/products/${productId}/inquiry`,
      requestBody,
      receiveRequestProductResponse,
      receiveRequestProductError
    )
  );
};

export const resetRequestProduct = () => ({
  type: RESET_REQUEST_PRODUCT,
});

const fetchFilters = () => (dispatch, getState) => {
  const { activeCategory, userSelectedReferenceDate } = getState().Product;
  const { categoryId, subCategoryId } = activeCategory;
  dispatch(exportFunctions.loadFilters());
  dispatch(
    fetchData(
      userSelectedReferenceDate
        ? createRouteForFetchFiltersWithReferenceDate(
            categoryId,
            subCategoryId,
            encodeURIComponent(userSelectedReferenceDate)
          )
        : createRouteForFetchFilters(categoryId, subCategoryId),
      receiveFilters
    )
  );
};

export const receiveFilters = (data) => ({
  type: RECEIVE_FILTERS,
  filters: data.data,
});

const loadProducts = () => ({
  type: LOAD_PRODUCTS,
});

const fetchProducts = (filters) => (dispatch, getState) => {
  const { activeCategory, productSearchParam, userSelectedReferenceDate } = getState().Product;
  const { categoryId, subCategoryId } = activeCategory;
  dispatch(exportFunctions.loadProducts());

  const data = {
    categoryId,
    subCategoryId,
    filters,
    search: productSearchParam,
    referenceDate: userSelectedReferenceDate,
  };
  dispatch(postDataAsync(ROUTE_PRODUCTS, data, receiveProducts));
};

export const receiveProducts = (data) => (dispatch) => {
  const products = data.data;
  // eslint-disable-next-line array-callback-return
  products.map((product) => {
    product.primaryGroups = [];
    product.secondaryGroups = [];
    product.properties.map((propertyGroup) => {
      const groupHasPrimaryProperty = _.get(propertyGroup, "primary", false);
      if (groupHasPrimaryProperty) {
        return product.primaryGroups.push(propertyGroup);
      }
      return product.secondaryGroups.push(propertyGroup);
    });
  });
  return dispatch({
    type: RECEIVE_PRODUCTS,
    products,
  });
};

export const resetFilters = () => async (dispatch) => {
  await dispatch({
    type: RESET_FILTERS,
  });
  await dispatch(resetFilterPanelExpanded());
  dispatch(exportFunctions.fetchFilters());
};

export const resetActiveFilters = () => (dispatch) => {
  dispatch({
    type: RESET_ACTIVE_FILTERS,
  });
};

export const setProductSearchParam = (productSearchParam) => (dispatch) =>
  dispatch({
    type: SET_SEARCH_PARAM,
    productSearchParam,
  });

export const setActiveCategory = (activeCategory) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_CATEGORY,
    activeCategory,
  });
};

export const resetProduct = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setFilter = (filter) => async (dispatch, getState) => {
  await dispatch({ type: SET_FILTER, filter });
  const { activeFilters } = getState().Product;
  // eslint-disable-next-line no-console
  console.log("active filters: ", activeFilters);
  dispatch(exportFunctions.fetchProducts(activeFilters));
};

export const receiveProductChanges = (data) => ({
  type: RECEIVE_PRODUCT_CHANGES,
  data,
});

export const getProductChanges = (date) => (dispatch, getState) => {
  const { categoryId, subCategoryId } = getState().Product.activeCategory;
  const data = { referenceDate: date, categoryId, subCategoryId };
  return dispatch(postData(ROUTE_PRODUCTS_SUMMARIZED_CHANGES, data, receiveProductChanges));
};

export const setUserSelectedReferenceDate = (userSelectedReferenceDate) => (dispatch) => {
  return dispatch({
    type: SET_USER_SELECTED_REFERENCE_DATE,
    userSelectedReferenceDate,
  });
};

const resetProductChanges = () => ({
  type: RESET_PRODUCT_CHANGES,
});

const exportFunctions = {
  loadCategories,
  loadFilters,
  fetchFilters,
  loadProducts,
  fetchProducts,
  resetProductChanges,
};

export default exportFunctions;
