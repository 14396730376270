import _ from "lodash";
import {
  RECEIVE_LANGUAGE_USER,
  RECEIVE_LOGIN,
  DELETE_TOKEN,
  SET_USER,
  RECEIVE_USER,
  RECEIVE_UPDATE_PASSWORD,
  RECEIVE_UPDATE_PASSWORD_ERROR,
  RESET_PASSWORD_ERRORS,
} from "./UserActions";
import { Api } from "../../Core/Api";

const initialState = {
  user: undefined,
  passwordErrors: {
    wrongPassword: false,
  },
  passwordUpdateSuccessful: false,
};

export default function UserReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_LANGUAGE_USER: {
      return {
        ...state,
        user: action.data,
      };
    }

    case RECEIVE_LOGIN: {
      const accessToken = action.data.access_token;
      Api.setToken(accessToken);
      const user = action.data.currentUser;
      return {
        ...state,
        user,
      };
    }

    case RECEIVE_USER: {
      const user = action.data;
      return {
        ...state,
        user,
      };
    }

    case RECEIVE_UPDATE_PASSWORD: {
      return {
        ...state,
        passwordUpdateSuccessful: true,
      };
    }

    case RECEIVE_UPDATE_PASSWORD_ERROR: {
      const violations = _.get(action.errorResponseData, "data.violations", []);
      const wrongPassword = !!_.find(violations, ["propertyPath", "currentPassword"]);

      const passwordErrors = {
        wrongPassword,
      };

      return {
        ...state,
        passwordErrors,
      };
    }

    case RESET_PASSWORD_ERRORS: {
      const passwordErrors = {
        wrongPassword: false,
      };
      return {
        ...state,
        passwordErrors,
        passwordUpdateSuccessful: false,
      };
    }

    case SET_USER:
      return {
        ...state,
        user: action.user,
      };

    case DELETE_TOKEN:
      return {
        ...state,
        user: undefined,
      };

    default:
      return state;
  }
}
