import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { CATEGORY_SELECTION } from "../../App";

const propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};
const defaultProps = {
  component: () => {},
};

const LoginRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.User.user);

  return (
    <Route
      {...rest}
      render={(props) =>
        !user ? <Component {...props} /> : <Redirect to={{ pathname: `/${CATEGORY_SELECTION}` }} />
      }
    />
  );
};

LoginRoute.propTypes = propTypes;
LoginRoute.defaultProps = defaultProps;

export default LoginRoute;
