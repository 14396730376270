import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { TextField } from "@material-ui/core";
import "./Filter.css";
import { setFilter } from "../../../Reducers/Product/ProductActions";

const propTypes = {
  filter: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};
const defaultProps = {
  filter: null,
  type: "text",
};
const FilterText = ({ type }, props) => {
  const { filter } = props;

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const dispatch = useDispatch();

  let { value } = filter;
  if (typeof value !== "string") value = "";

  const [debouncedCallback] = useDebouncedCallback(() => {
    dispatch(setFilter(filter));
  }, 1000);

  const handleChange = (event) => {
    event.persist();
    const _value = event.target.value;
    forceUpdate();
    filter.value = _value;
    debouncedCallback(filter.value);
  };

  return (
    <React.Fragment>
      <TextField
        id={filter.id}
        label={filter.label}
        className={`
            Filter-text
            ${filter.value ? "--active" : ""}
          `}
        type={type}
        value={value}
        onChange={handleChange}
        margin="normal"
      />
    </React.Fragment>
  );
};

FilterText.propTypes = propTypes;
FilterText.defaultProps = defaultProps;

export default React.memo(FilterText);
