import _ from "lodash";
import {
  SET_EXPANDED_FILTER_PANEL,
  RESET_EXPANDED_FILTER_PANELS,
  SET_EXPANDED_FILTER_DETAIL_PANEL,
} from "./FilterActions";

const initialState = {
  expandedFilterPanels: [],
  expandedFilterDetailsPanels: [],
};

export default function FilterReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_EXPANDED_FILTER_PANEL: {
      const { expandedFilterPanels } = state;
      const { groupName } = action;
      const isExpanded = !!_.find(expandedFilterPanels, ["panelName", groupName]);
      if (!isExpanded) {
        expandedFilterPanels.push({ panelName: groupName });
      } else {
        _.remove(expandedFilterPanels, (n) => n.panelName === groupName);
      }
      return {
        ...state,
        expandedFilterPanels,
      };
    }
    case SET_EXPANDED_FILTER_DETAIL_PANEL: {
      const { expandedFilterDetailsPanels } = state;
      const { filterName } = action;
      const isExpanded = !!_.find(expandedFilterDetailsPanels, ["detailsPanelName", filterName]);
      if (!isExpanded) {
        expandedFilterDetailsPanels.push({ detailsPanelName: filterName });
      } else {
        _.remove(expandedFilterDetailsPanels, (n) => n.detailsPanelName === filterName);
      }
      return {
        ...state,
        expandedFilterDetailsPanels,
      };
    }
    case RESET_EXPANDED_FILTER_PANELS: {
      return {
        ...state,
        expandedFilterPanels: [],
        expandedFilterDetailsPanels: [],
      };
    }
    default:
      return state;
  }
}
