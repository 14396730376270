import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginLeft: theme.spacing(1),
      width: 150,
    },
  },
  clearButton: {
    padding: 0,
    opacity: 0.5,
  },
}));

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const SearchForm = (props) => {
  const initialValue = useSelector((state) => state.Product.productSearchParam);
  const [searchValue, setSearchValue] = useState(initialValue);
  const { t } = useTranslation("searchForm");

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const triggerChange = () => {
    props.onSubmit(searchValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      triggerChange();
    }
  };

  const resetSearchValue = (event) => {
    event.preventDefault();
    setSearchValue("");
    props.onSubmit("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const classes = useStyles();

  return (
    <form className={classes.root} onSubmit={handleSubmit} noValidate autoComplete="off">
      <Tooltip title={t("tooltip")} placement="top">
        <TextField
          label={t("label")}
          id="outlined-size-small"
          size="small"
          variant="outlined"
          value={searchValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          InputProps={
            searchValue !== ""
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className={classes.clearButton}
                        aria-label="cancel"
                        onClick={resetSearchValue}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : {}
          }
        />
      </Tooltip>
    </form>
  );
};

SearchForm.propTypes = propTypes;
export default SearchForm;
