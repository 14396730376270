import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterMemoized from "./Filter";
import removeLeadingAsterisks from "../../../utils/helpers";
import { setFilterPanelExpanded } from "../../../Reducers/Filter/FilterActions";
import { LOCALE_GERMAN } from "../../../assets/Translations/i18n";

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.array,
  filtersLoaded: PropTypes.bool,
  showNoFiltersLabel: PropTypes.bool,
  filtersEmpty: PropTypes.bool,
};
const defaultProps = {
  filters: null,
  filtersLoaded: false,
  showNoFiltersLabel: false,
  filtersEmpty: false,
};

const FilterList = (props) => {
  const { filters, filtersLoaded, showNoFiltersLabel, filtersEmpty } = props;

  const { t } = useTranslation("overview");
  const expandedFilterPanels = useSelector((state) => state.Filter.expandedFilterPanels);
  const dispatch = useDispatch();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const toggleExpanded = async (groupName) => {
    await dispatch(setFilterPanelExpanded(groupName));
    forceUpdate();
  };

  if (!filters) return null;

  return (
    <div className="FilterList">
      {filtersLoaded &&
        !filtersEmpty &&
        filters.map((filterGroup, i) => {
          const groupName = filterGroup.groupName ? removeLeadingAsterisks(filterGroup.groupName) : "";
          const groupedFilters = _.get(filterGroup, "filters", []);
          const panelExpanded = !!_.find(expandedFilterPanels, ["panelName", groupName]);
          if (groupName !== "") {
            return (
              <React.Fragment key={`filter${i.toString()}`}>
                <ExpansionPanel
                  style={{ flexDirection: "column", display: "flex" }}
                  className="Filter"
                  expanded={panelExpanded}
                >
                  <ExpansionPanelSummary
                    onClick={() => toggleExpanded(groupName)}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="OverViewPage-filter-group-container">
                      {filterGroup.icon ? (
                        <img
                          className="Filter-icon"
                          src={filterGroup.icon}
                          alt=""
                        />
                      ) : (
                        <div className="Filter-icon" />
                      )}
                      <Typography className="OverviewPage-filter-group">{groupName}</Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {groupedFilters.map((f) => (
                      <FilterMemoized key={f.id} filter={f} />
                    ))}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={filterGroup.identifier}>
              {groupedFilters.map((f) => (
                <FilterMemoized key={f.id} filter={f} noGroupName />
              ))}
            </React.Fragment>
          );
        })}
      {!filtersLoaded && <CircularProgress />}
      {showNoFiltersLabel && <div className="NoFiltersLabel">{t("noFilterFound")}</div>}
    </div>
  );
};
FilterList.propTypes = propTypes;
FilterList.defaultProps = defaultProps;

export default React.memo(FilterList);
