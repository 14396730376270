import { postData, putData, fetchData } from "../Api/ApiActions";

export const RECEIVE_LOGIN = "USER_RECEIVE_LOGIN";
export const RECEIVE_LANGUAGE_USER = "USER_RECEIVE_LANGUAGE_USER";
export const DELETE_TOKEN = "USER_DELETE_TOKEN";
export const SET_USER = "USER_SET_USER";
export const RECEIVE_USER = "USER_RECEIVE_USER";
export const RECEIVE_UPDATE_PASSWORD = "USER_RECEIVE_UPDATE_PASSWORD";
export const RECEIVE_UPDATE_PASSWORD_ERROR = "USER_RECEIVE_UPDATE_PASSWORD_ERROR";
export const RESET_PASSWORD_ERRORS = "USER_RESET_PASSWORD_ERRORS";

export const ROUTE_ME = "api/me";
export const ROUTE_LOGIN = "/oauth/v2/token";

export const receiveLogin = (data) => ({
  type: RECEIVE_LOGIN,
  data,
});

export const receiveLanguageUser = (data) => ({
  type: RECEIVE_LANGUAGE_USER,
  data,
});

export const receiveUpdatePassword = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_UPDATE_PASSWORD,
    data,
  });
};

export const receiveUpdatePasswordError = (source, error, errorResponseData) => (dispatch) => {
  dispatch({
    type: RECEIVE_UPDATE_PASSWORD_ERROR,
    errorResponseData,
  });
};

export const login = (user, password, deviceInfo) =>
  postData(
    ROUTE_LOGIN,
    {
      grant_type: "password",
      client_id: `${process.env.REACT_APP_OAUTH_CLIENT}`,
      client_secret: `${process.env.REACT_APP_OAUTH_SECRET}`,
      username: user,
      password,
      scope: "user",
      deviceInfo,
    },
    receiveLogin
  );

export const setLanguage = (language) => (dispatch, getState) => {
  const { user } = getState().User;
  user.language = language;
  return dispatch(putData(ROUTE_ME, user, receiveLanguageUser));
};

export const updatePassword = (currentPassword, password, passwordConfirmation) => (dispatch) =>
  dispatch(
    putData(
      ROUTE_ME,
      {
        currentPassword,
        password,
        passwordConfirmation,
      },
      receiveUpdatePassword,
      receiveUpdatePasswordError
    )
  );

export const receiveUser = (data) => ({
  type: RECEIVE_USER,
  data,
});

export const getUser = () => (dispatch) => {
  dispatch(fetchData(ROUTE_ME, receiveUser));
};

export const deleteToken = () => ({
  type: DELETE_TOKEN,
});

export const setUser = (user) => ({
  type: SET_USER,
  user,
});

export const resetPasswordErrors = () => ({
  type: RESET_PASSWORD_ERRORS,
});
