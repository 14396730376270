import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import { getIsActive } from "./Filter";
import "./Filter.css";
import { setFilter } from "../../../Reducers/Product/ProductActions";

const propTypes = {
  filter: PropTypes.objectOf(PropTypes.any),
};
const defaultProps = {
  filter: null,
};

const useStyles = makeStyles({
  label: {
    textAlign: "left",
  },
});

const FilterCheckbox = (props) => {
  const { filter } = props;
  const { t } = useTranslation("filterCheckBox");
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const dispatch = useDispatch();
  const classes = useStyles();

  const { options } = filter;

  const handleChange = (event, { option }) => {
    const { checked } = event.target;
    option.checked = checked;
    dispatch(setFilter(filter));
    forceUpdate();
  };

  return (
    <FormGroup className="Filter-checkbox">
      {options.map((option) => (
        <FormControlLabel
          key={`key_${option.value}`}
          className={`
            Filter-option
            ${getIsActive(option) ? "--active" : ""}
          `}
          control={
            <Checkbox
              checked={option.checked || false}
              onChange={(e) => {
                handleChange(e, { option });
              }}
              value={option.value}
              color="primary"
            />
          }
          classes={{
            label: classes.label,
          }}
          /* eslint-disable-next-line no-nested-ternary */
          label={option.value === "true" ? t("yes") : option.value === "false" ? t("no") : option.value}
        />
      ))}
    </FormGroup>
  );
};

FilterCheckbox.propTypes = propTypes;
FilterCheckbox.defaultProps = defaultProps;

export default React.memo(FilterCheckbox);
