import _ from "lodash";
import {
  CHANGE_FILTERS,
  LOAD_FILTERS,
  LOAD_CATEGORIES,
  LOAD_PRODUCTS,
  RECEIVE_FILTERS,
  RECEIVE_CATEGORIES,
  RECEIVE_PRODUCTS,
  SET_FILTER,
  RESET_FILTERS,
  RESET_ACTIVE_FILTERS,
  SET_ACTIVE_CATEGORY,
  RESET,
  SET_SEARCH_PARAM,
  RECEIVE_PRODUCT_CHANGES,
  SET_USER_SELECTED_REFERENCE_DATE,
  RESET_PRODUCT_CHANGES,
  SEND_PRODUCT_REQUEST,
  RECEIVE_REQUEST_PRODUCT_RESPONSE,
  RECEIVE_REQUEST_PRODUCT_ERROR, RESET_REQUEST_PRODUCT
} from "./ProductActions";

import { CHECKBOX, RADIO, RANGE, TEXT } from "../../Core/Constants";

const initialState = {
  activeFilters: [],
  products: [],
  filters: [],
  activeCategory: null,
  categories: [],
  categoriesLoaded: false,
  fetchingCategories: false,
  filtersLoaded: false,
  productsLoaded: false,
  fetchingFilters: false,
  fetchingProducts: false,
  productSearchParam: "",
  productChanges: null,
  userSelectedReferenceDate: null,
  sendingRequestProductRequest: false,
  requestProductSuccess: null,
};

export default function ProductReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_FILTERS: {
      return {
        ...state,
        filtersLoaded: false,
        fetchingFilters: true,
      };
    }

    case RECEIVE_FILTERS: {
      return {
        ...state,
        filters: action.filters,
        filtersLoaded: true,
        fetchingFilters: false,
      };
    }

    case RESET_FILTERS: {
      return {
        ...state,
        filters: [],
        activeFilters: [],
      };
    }

    case RESET_ACTIVE_FILTERS: {
      return {
        ...state,
        activeFilters: [],
      };
    }

    case LOAD_CATEGORIES: {
      return {
        ...state,
        categoriesLoaded: false,
        fetchingCategories: true,
      };
    }

    case RECEIVE_CATEGORIES: {
      return {
        ...state,
        categories: action.data,
        categoriesLoaded: true,
        fetchingCategories: false,
      };
    }

    case LOAD_PRODUCTS: {
      return {
        ...state,
        productsLoaded: false,
        fetchingProducts: true,
      };
    }

    case SEND_PRODUCT_REQUEST: {
      return {
        ...state,
        sendingRequestProductRequest: true,
      };
    }

    case RECEIVE_REQUEST_PRODUCT_RESPONSE: {
      return {
        ...state,
        sendingRequestProductRequest: false,
        requestProductSuccess: true,
      };
    }

    case RECEIVE_REQUEST_PRODUCT_ERROR: {
      return {
        ...state,
        sendingRequestProductRequest: false,
        requestProductSuccess: false,
      };
    }

    case RESET_REQUEST_PRODUCT: {
      return {
        ...state,
        requestProductSuccess: null,
      }
    }

    case RECEIVE_PRODUCTS: {
      return {
        ...state,
        productsLoaded: true,
        fetchingProducts: false,
        products: action.products,
      };
    }

    case CHANGE_FILTERS: {
      return {
        ...state,
        activeFilters: action.filters,
      };
    }

    case SET_FILTER: {
      const { filter } = action;
      const { id, type, value } = filter;
      const { activeFilters } = state;

      const apiFilter = {
        id,
        filterType: type,
        value: [],
      };

      switch (type) {
        case CHECKBOX:
          _.each(filter.options, (o) => {
            if (o.checked) {
              apiFilter.value.push(o.value);
            }
          });
          break;

        case RADIO:
          if (value === "false") {
            _.remove(apiFilter, { id });
          } else {
            apiFilter.value.push(value);
          }
          break;

        case RANGE:
          // If the slider is set to the default values, it should not be active
          if (value[0] === filter.min && value[1] === filter.max) {
            _.remove(apiFilter, { id });
          } else {
            apiFilter.value = value;
          }
          break;

        case TEXT:
          if (value === "") {
            _.remove(apiFilter, { id });
          } else {
            // api really always expects strings here
            apiFilter.value.push(value.toString());
          }
          break;

        default:
          break;
      }

      _.remove(activeFilters, { id });
      if (apiFilter.value.length > 0) {
        activeFilters.push(apiFilter);
      }

      return {
        ...state,
        activeFilters,
      };
    }

    case SET_ACTIVE_CATEGORY: {
      const { activeCategory } = action;
      return {
        ...state,
        activeFilters: [],
        products: [],
        filters: [],
        filtersLoaded: false,
        productsLoaded: false,
        fetchingFilters: false,
        fetchingProducts: false,
        activeCategory,
      };
    }
    case RESET: {
      return {
        ...initialState,
      };
    }

    case SET_SEARCH_PARAM: {
      const { productSearchParam } = action;
      return {
        ...state,
        productSearchParam,
      };
    }

    case RECEIVE_PRODUCT_CHANGES: {
      const { data } = action;
      return {
        ...state,
        productChanges: data,
      };
    }

    case SET_USER_SELECTED_REFERENCE_DATE: {
      const { userSelectedReferenceDate } = action;
      return {
        ...state,
        userSelectedReferenceDate,
      };
    }

    case RESET_PRODUCT_CHANGES: {
      return {
        ...state,
        productChanges: null,
        userSelectedReferenceDate: null,
      };
    }

    default:
      return state;
  }
}
