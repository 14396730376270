import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translations from "./Translations";

export const LOCALE_GERMAN = "de_DE";
export const LOCALE_ENGLISH = "en_US";

i18n.use(LanguageDetector).init({
  detection: {
    order: ["querystring", "cookie", "localStorage"],
    lookupQuerystring: "lng",
    lookupCookie: "i18next",
    caches: ["cookie"],
    // ten years from now
    cookieMinutes: 5256000,
  },
  fallbackLng: LOCALE_GERMAN,
  resources: translations,
  ns: ["common"],
  defaultNS: "common",
  fallbackNS: "common",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
