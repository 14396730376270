import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import removeLeadingAsterisks from "../../../utils/helpers";
import { setFilterDetailPanelExpanded } from "../../../Reducers/Filter/FilterActions";
import useFilterState from "../../../hooks/useFilterState";
import FilterCheckboxMemoized from "./FilterCheckbox";
import FilterTextMemoized from "./FilterText";
import FilterSliderMemoized from "./FilterSlider";
import { NewBadgeSmall } from "../NewBadge/NewBadge";

import "./Filter.css";
import FilterSwitchMemoized from "./FilterSwitch";

import { CHECKBOX, SWITCH, RADIO, BOOLEAN, NUMBER, RANGE, TEXT } from "../../../Core/Constants";

export const filterShape = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  active: PropTypes.boolean,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.Number), // min-max range slider
  ]),
  min: PropTypes.number, // min-max range slider
  max: PropTypes.number, // min-max range slider
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onUpdate: PropTypes.func.isRequired,
};
const propTypes = {
  filter: PropTypes.objectOf(PropTypes.any),
  noGroupName: PropTypes.bool,
};
const defaultProps = {
  filter: null,
  noGroupName: false,
};

export const getIsActive = (filter) => {
  let result = false;

  const { type } = filter;

  switch (type) {
    case CHECKBOX:
    case RADIO:
      result = filter.options.some((option) => getIsActive(option));
      break;
    default:
      result = filter.value !== filter.initial;
  }

  return result;
};

const Filter = (props) => {
  const { filter, noGroupName } = props;

  const expandedFilterDetailsPanels = useSelector((state) => state.Filter.expandedFilterDetailsPanels);

  const activeFilters = useSelector((state) => state.Product.activeFilters);
  const currentFilterIsActive = !!_.find(activeFilters, ["id", filter.id]);
  const dispatch = useDispatch();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [switchExpanded, setSwitchExpanded] = useFilterState(!!currentFilterIsActive);
  const [checkBoxExpanded, setCheckBoxExpanded] = useFilterState(!!currentFilterIsActive);
  const [textExpanded, setTextExpanded] = useFilterState(!!currentFilterIsActive);
  const [sliderExpanded, setSliderExpanded] = useFilterState(!!currentFilterIsActive);
  const { t } = useTranslation("filter");

  const { type } = filter;
  const name = removeLeadingAsterisks(filter.name);

  const activeFlag = getIsActive(filter);
  // todo: --active class deactivated, reactivate after getIsActive Handling is fixed
  const filterActiveClass = activeFlag ? "" : "";

  const toggleExpanded = async () => {
    await dispatch(setFilterDetailPanelExpanded(name));
    switch (type) {
      case CHECKBOX:
        setCheckBoxExpanded(true);
        break;
      case SWITCH:
      case RADIO:
      case BOOLEAN:
        setSwitchExpanded(true);
        break;
      case NUMBER:
      case RANGE:
        setSliderExpanded(true);
        break;
      case TEXT:
        setTextExpanded(true);
        break;
      default:
        break;
    }
    forceUpdate();
  };

  const panelExpanded = !!_.find(expandedFilterDetailsPanels, ["detailsPanelName", name]);

  return (
    <ExpansionPanel className={`Filter ${filterActiveClass}`} expanded={panelExpanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={toggleExpanded}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {filter.icon ? (
            <img className="Filter-icon" src={filter.icon} alt="" />
          ) : (
            <div className="Filter-icon" />
          )}
          <Typography
            className={noGroupName ? "Filter-noGroupName" : "Filter-header"}
            style={{
              width: "100%",
            }}
          >
            {name}
          </Typography>
          {filter.isNew ? <NewBadgeSmall /> : <div />}
        </div>
      </ExpansionPanelSummary>
      {type === CHECKBOX && (
        <ExpansionPanelDetails>
          {(checkBoxExpanded || panelExpanded) && <FilterCheckboxMemoized filter={filter} />}
        </ExpansionPanelDetails>
      )}
      {(type === SWITCH || type === RADIO || type === BOOLEAN) && (
        <ExpansionPanelDetails>
          {(switchExpanded || panelExpanded) && <FilterSwitchMemoized filter={filter} />}
        </ExpansionPanelDetails>
      )}
      {(type === NUMBER || type === RANGE) && (
        <ExpansionPanelDetails>
          {(sliderExpanded || panelExpanded) && filter.min !== filter.max && (
            <FilterSliderMemoized filter={filter} />
          )}
          {(sliderExpanded || panelExpanded) && filter.min === filter.max && (
            <Typography>{t("allItemsSameValue", { value: filter.max })}</Typography>
          )}
        </ExpansionPanelDetails>
      )}
      {type === TEXT && (
        <ExpansionPanelDetails>
          {(textExpanded || panelExpanded) && <FilterTextMemoized filter={filter} />}
        </ExpansionPanelDetails>
      )}
    </ExpansionPanel>
  );
};
Filter.propTypes = propTypes;
Filter.defaultProps = defaultProps;

export default React.memo(Filter);
