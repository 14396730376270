import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import Schedule from "@material-ui/icons/Schedule";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { LOCALE_GERMAN } from "../../../assets/Translations/i18n";
import { getUser } from "../../../Reducers/User/UserActions";
import ACTIONS, {
  getProductChanges,
  resetFilters,
  setUserSelectedReferenceDate,
} from "../../../Reducers/Product/ProductActions";
import useOuterClickNotifier from "../../../hooks/useOuterClickNotifier";
import { formatDate } from "../../../utils/helpers";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiListItemText-primary": {
      fontWeight: 600,
    },
  },
  active: {
    backgroundColor: "#F5F5F5",
  },
  menu: {
    zIndex: 50,
    position: "absolute",
    top: "65px",
    right: "15px"
  },
  label: {
    display: "flex",
  },
  container: {
    margin: "0 0.5em 0 0.5em",
  },
  button: {
    backgroundColor: "#E0E0E0",
    maxWidth: 250,
    minWidth: 200,
    marginTop: 8,
    borderRadius: 8,
    borderWidth: "1px",
    borderColor: "#e74011",
    borderStyle: "solid"
  },
  collapsableContainer: {
    overflow: "visible"
  },
  collapsable: {
    marginTop: 8,
    width: 400,
    marginLeft: "-240px",
  },
  changesSinceLabel: {
    minWidth: "120px !important",
    flex: "0 1 auto !important",
    marginTop: "3px !important",
    marginRight: "6px !important",
    fontWeight: "600 !important",
  },
  datePickerWrapper: {
    width: "215px"
  },
  datePicker: {
    display: "flex",
    flexDirection: "column",   
  },
  link: {
    color: "black",
    textDecoration: "underline",
    fontSize: "0.8rem",
    marginLeft: 100,
    opacity: 0.6,
  },
  changesText: {
    display: "flex",
    alignSelf: "flex-start",
  },
}));

export const ProductChangesMenu = () => {
  const productChanges = useSelector((state) => state.Product.productChanges);
  const user = useSelector((state) => state.User.user);
  const lastLogin = get(user, "lastLoginDate", new Date().toISOString());
  const totalChanges = get(productChanges, "totalChanges", 0);
  const referenceDate = get(productChanges, "referenceDate", lastLogin);
  const newFilters = get(productChanges, "newFilters", 0);
  const newProducts = get(productChanges, "newProducts", 0);
  const changedProducts = get(productChanges, "changedProducts", 0);
  const changedProductProperties = get(productChanges, "changedProductProperties", 0);
  const [selectedDate, setSelectedDate] = useState(referenceDate);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const minDate = new Date("2019-01-01");

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (isValid(date) && date >= minDate) {
      setUserReferenceDate(date).then(() => fetchChanges(date));
    }
  };

  const setUserReferenceDate = (date) => {
    const formattedDate = formatDate(date);
    return Promise.resolve(dispatch(setUserSelectedReferenceDate(formattedDate)));
  };

  const dispatch = useDispatch();
  const language = get(user, "language");

  useEffect(() => {
    if (!user) {
      dispatch(getUser());
    }
  }, [dispatch, user]);

  const germanSelected = language === LOCALE_GERMAN;
  const classes = useStyles();

  const { t } = useTranslation("productChangesMenu");
  const [open, setOpen] = useState(false);

  const toggleCollapse = () => Promise.resolve(setOpen(!open));

  const innerRef = useRef(null);
  useOuterClickNotifier(() => {
    if (open && !datePickerOpen) {
      return toggleCollapse();
    }
  }, innerRef);

  const toggleDatePickerOpen = () => {
    setDatePickerOpen(!datePickerOpen);
  };

  const handleAccept = (date) => {
    setUserReferenceDate(date).then(() => fetchChanges(date));
  };

  const fetchChanges = (date) => {
    dispatch(getProductChanges(date ? formatDate(date) : formatDate(selectedDate)))
      .then(dispatch(resetFilters()))
      .then(dispatch(ACTIONS.fetchProducts()));
  };

  const setLastLogin = (event) => {
    event.preventDefault();
    setUserReferenceDate(lastLogin).then(() => {
      setSelectedDate(lastLogin);
      fetchChanges(lastLogin);
    });
  };

  const lastLoginDate = format(new Date(lastLogin), germanSelected ? "dd.MM.yyyy" : "yyyy-MM-dd");
  return (
    <div className={classes.menu} ref={innerRef}>
      <List>
        <ListItem button onClick={toggleCollapse} className={classes.button}>
          <ListItemText
            className={classes.container}
            primary={
              <Typography className={classes.label} autoCapitalize="true">
                <span className={classes.userName}>{t("changes")}</span>
              </Typography>
            }
          />
          <Badge badgeContent={totalChanges} color="primary" className={classes.badge} max={9999}>
            <Schedule />
          </Badge>
        </ListItem>
        <Collapse in={open} timeout="auto" className={classes.collapsableContainer}>
          <Paper className={classes.collapsable}>
            <List disablePadding>
              <ListItem className={classes.datePicker}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={germanSelected ? deLocale : enLocale}>
                  <Grid container justify="flex-start">
                    <ListItemText className={classes.changesSinceLabel} primary={t("changesSince")} />
                    <div className={classes.datePickerWrapper}>
                    <KeyboardDatePicker                    
                      open={datePickerOpen}
                      onOpen={toggleDatePickerOpen}
                      onClose={toggleDatePickerOpen}
                      onAccept={handleAccept}
                      margin="none"
                      id="date-picker-dialog"
                      minDate={"2019-01-01"}
                      maxDateMessage={t("minDateMessage")}
                      minDateMessage={t("minDateMessage")}
                      format={germanSelected ? "dd.MM.yyyy" : "MM/dd/yyyy"}
                      invalidDateMessage={t("invalidDateMessage")}
                      value={selectedDate}
                      onChange={handleDateChange}
                      disableFuture
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      cancelLabel={t("cancelLabel")}
                      okLabel={t("okLabel")}
                    />
                    </div>
                  </Grid>
                </MuiPickersUtilsProvider>
                <Link
                  href="#"
                  onClick={(event) => setLastLogin(event)}
                  variant="body1"
                  className={classes.link}
                >
                  {t("setToLastLogin", { lastLogin: lastLoginDate })}
                </Link>
                <Typography className={classes.changesText}>
                  {t("amountNewProducts", { count: newProducts })}
                </Typography>
                <Typography className={classes.changesText}>
                  {t("amountChangedProducts", { count: changedProducts })}
                </Typography>
                <Typography className={classes.changesText}>
                  {t("amountNewFilters", { count: newFilters })}
                </Typography>
                <Typography className={classes.changesText}>
                  {t("amountChangedProperties", { count: changedProductProperties })}
                </Typography>

              </ListItem>
            </List>
          </Paper>
        </Collapse>
      </List>
    </div>
  );
};
